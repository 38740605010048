import { mapActions } from 'vuex'
import { BLOCKCHAIN_BINANCE, BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_POLYGON } from '@/constants/blockchain'

export default {
  props: {
    onSelectNetworkCb: Function,
  },
  data () {
    return {
      blockchain: BLOCKCHAIN_ETHEREUM,
      BLOCKCHAIN_ETHEREUM,
      BLOCKCHAIN_BINANCE,
      BLOCKCHAIN_POLYGON,
      selectedBlockchains: {
        [BLOCKCHAIN_ETHEREUM]: null,
        [BLOCKCHAIN_BINANCE]: null,
        [BLOCKCHAIN_POLYGON]: null
      }
    }
  },
  computed: {
    isSelectedBlockchain () {
      return Object.values(this.selectedBlockchains).includes('selected')
    }
  },
  methods: {
    ...mapActions({
      connectToWallet: 'network/connect',
      closeModal: 'ui/closeModal',
    }),
    selectBlockchain (blockchain) {
      this.selectedBlockchains[blockchain] = this.selectedBlockchains[blockchain] === null ? 'selected' : null
    },
    confirmBlockchains () {
      const chainIds = []
      Object.keys(this.selectedBlockchains).map(
        chainId => {
          if (this.selectedBlockchains[chainId] !== null) {
            chainIds.push(chainId)
          }
        }
      )
      this.onSelectNetworkCb(chainIds)
      this.closeModal()
    }
  },
}
