<bg-modal class="root select-connect-wallet">
  <h3 class="modal-title">Select network</h3>
  <div class="content">
    <div class="blockchains">
      <h4 class="blockchains-title">
        To connect to WalletConnect, you need to select a network or networks
      </h4>
      <div
          class="row blockchain"
          @click="selectBlockchain(BLOCKCHAIN_ETHEREUM)"
          :class="selectedBlockchains[BLOCKCHAIN_ETHEREUM]"
      >
        <tw-icon class="blockchain-logo icon eth-icon" icon="eth"/>
        <span>Ethereum</span>
      </div>
      <div
          class="row blockchain"
          @click="selectBlockchain(BLOCKCHAIN_BINANCE)"
          :class="selectedBlockchains[BLOCKCHAIN_BINANCE]"
      >
        <tw-icon class="blockchain-logo icon bsc-icon" icon="bsc"/>
        <span>BSC</span>
      </div>
      <div
          class="row blockchain"
          @click="selectBlockchain(BLOCKCHAIN_POLYGON)"
          :class="selectedBlockchains[BLOCKCHAIN_POLYGON]"
      >
        <tw-icon class="blockchain-logo icon polygon-icon" icon="polygon"/>
        <span>Polygon</span>
      </div>
      <div class="btn-wrapper">
        <button
          @click="confirmBlockchains"
          class="btn-confirm-blockchains"
          :disabled="!isSelectedBlockchain"
        >
          <span>Confirm</span>
        </button>
      </div>
    </div>
  </div>
</bg-modal>
