<div class="info">
  <div class="container">
    <div class="top">
      <fieldset class="buy">
        <legend>
          <img src="/static/images/icon/time.svg" alt="TIME" />
          Buy Time
        </legend>
        <div class="exchanges">
          <div class="exchange">
            <a class="link" href="https://timex.io/buy-time" target="_blank">
              <img src="/static/images/icon/timex.svg" alt="TimeX" />
              <span>TimeX</span>
            </a>
          </div>
          <div class="exchange">
            <a class="link" href="https://pro.coinbase.com/trade/TIME-USD" target="_blank">
              <img src="/static/images/icon/coinbase.svg" alt="Coinbase" />
              <span>Coinbase</span>
            </a>
          </div>
          <div class="exchange">
            <a class="link" href="https://trade.kucoin.com/TIME-BTC" target="_blank">
              <img src="/static/images/icon/kucoin.svg" alt="KuCoin" />
              <span>KuCoin</span>
            </a>
          </div>
          <div class="exchange">
            <a class="link" href="https://www.gate.io/trade/TIMECHRONO_USDT" target="_blank">
              <img class="gate" src="/static/images/icon/gate.svg?1" alt="Gate.io" />
              <span>Gate.io</span>
            </a>
          </div>
          <div class="exchange">
            <a class="link" href="https://www.bybit.com/en-US/trade/spot/TIME/USDT" target="_blank">
              <img class="bybit" src="/static/images/icon/bybit.png" alt="Bybit" />
              <span>Bybit</span>
            </a>
          </div>
          <div class="exchange">
            <a class="link" href="https://www.bitget.com/en/spot/TIMEUSDT_SPBL?type=spot" target="_blank">
              <img src="/static/images/icon/bitget.png" alt="Bitget" />
              <span>Bitget</span>
            </a>
          </div>
          <div class="exchange">
            <a class="link"
               href="https://pancakeswap.finance/swap?outputCurrency=0x3b198e26E473b8faB2085b37978e36c9DE5D7f68"
               target="_blank">
              <img src="/static/images/icon/pancake-1x.png" srcset="/static/images/icon/pancake-2x.png 2x"
                   alt="Pancake" />
              <span>Pancake</span>
            </a>
          </div>
          <div class="exchange exchange-quickswap">
            <a class="link"
               href="https://quickswap.exchange/#/swap?inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0xb970bc1bd4fcd639c37aa8efd6713eadc577252f"
               target="_blank">
              <img src="/static/images/icon/quickswap.png" srcset="/static/images/icon/quickswap.png 2x"
                   alt="Quickswap" />
              <span>QuickSwap</span>
            </a>
          </div>
        </div>
      </fieldset>
      <fieldset class="rate">
        <legend>
          <img src="/static/images/icon/cmc.svg" alt="CoinMarketCap" />
          CoinMarketCap
        </legend>
        <div class="content">
          <div class="market-cap">
            <div class="label">Market Cap:</div>
            <div class="value">${{ marketCapFormatted }}</div>
          </div>
          <div class="second">
            <div class="price">
              <div class="label">Price:</div>
              <div class="value">${{ timePriceFormatted }}</div>
            </div>
            <div class="supply">
              <div class="label">Total Supply:</div>
              <div class="value">710,113</div>
            </div>
          </div>
          <a class="external-link" href="https://coinmarketcap.com/currencies/chrono-tech/" target="_blank">
            <tw-icon icon="link" />
            coinmarketcap.com/currencies/chrono-tech
          </a>
        </div>
      </fieldset>
    </div>
    <div class="bottom">
      <fieldset class="timewarp">
        <legend>
          Stake TIME
        </legend>
        <div class="content">
          <div class="timewarp-project">
            <img src="/static/images/icon/timewarp-1x.png" srcset="/static/images/icon/timewarp-2x.png 2x"
                 alt="TimeWarp" />
            <div>TimeWarp</div>
          </div>
          <a class="external-link" href="https://timewarp.finance" target="_blank">
            <tw-icon icon="link" />
            timewarp.finance
          </a>
        </div>
      </fieldset>
      <fieldset class="ecosystem">
        <legend>
          Chrono Ecosystem
        </legend>
        <div class="content">
          <div class="projects">
            <div
              v-for="(project, i) in projects"
              class="project"
              :class="{ active: i === activeProject }"
              :key="project.name"
              @click="activeProject = i"
              @mouseenter="activeProject = i"
            >
              <img :src="project.logo" :alt="project.name" />
            </div>
          </div>
          <div class="active-project">
            <div class="text">{{ projects[activeProject].text }}</div>
            <a class="external-link" :href="projects[activeProject].url" target="_blank">
              <tw-icon icon="link" />
              {{ urlFormatted(projects[activeProject].url) }}
            </a>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</div>
