import { mapGetters } from 'vuex'
import Contracts from './Contracts/Contracts.vue'
import FooterBlock from '@/partials/Footer/Footer.vue'
import HeaderBlock from '@/partials/Header/Header.vue'
import History from './History/History.vue'
import Info from './Info/Info.vue'
import Pending from './Pending/Pending.vue'
import Swap from './Swap/Swap.vue'

export default {
  name: 'Home',
  computed: {
    ...mapGetters({
      historyTransactions: 'network/historyTransactions',
    }),
    maintenance () {
      return Math.floor(Date.now() / 1000) > 1663185600
    },
  },
  components: {
    Contracts,
    FooterBlock,
    HeaderBlock,
    History,
    Info,
    Pending,
    Swap,
  },
}
