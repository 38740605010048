import { BLOCKCHAIN_BINANCE } from '@/constants/blockchain'
import { getWeb3Async } from '@/servicies/web3'
import { balanceOf } from '@/servicies/contracts/Erc20'

export const getBalance = async ({ blockchain = BLOCKCHAIN_BINANCE, contractAddress, address }) => {
  let balance = null
  if (contractAddress === '0x0000000000000000000000000000000000000000') {
    const web3 = await getWeb3Async({ blockchain })
    balance = await web3.eth.getBalance(address)
  } else {
    balance = await balanceOf({
      blockchain,
      contractAddress,
      who: address,
    })
  }
  return balance
}
