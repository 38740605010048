import EventEmitter from 'events'
import {
  BLOCKCHAIN_BINANCE,
  BLOCKCHAIN_ETHEREUM,
  BLOCKCHAIN_POLYGON,
  NAME_BY_BLOCKCHAIN,
  PROVIDER_BY_CHAIN_ID,
  EXPLORER_BY_BLOCKCHAIN,
  NATIVE_CURRENCY_BY_BLOCKCHAIN,
} from '@/constants/blockchain'
import { toChecksumAddress } from 'web3-utils'

const ethereum = (typeof window !== 'undefined') && window.ethereum

export const eventEmitter = new EventEmitter()

if (ethereum) {
  ethereum.autoRefreshOnNetworkChange = false

  ethereum.on('accountsChanged', async (accounts) => {
    if (accounts && Array.isArray(accounts) && accounts.length > 0) {
      eventEmitter.emit(
        'accountsChanged',
        accounts.map(item => toChecksumAddress(item)),
      )
    }
    if (accounts && Array.isArray(accounts) && accounts.length === 0) {
      eventEmitter.emit('disconnect')
    }
  })

  ethereum.on('chainChanged', (chainId) => {
    if (chainId) {
      eventEmitter.emit('chainChanged', parseInt(chainId, 16))
    }
  })

  ethereum.on('connect', () => {
    eventEmitter.emit('connect')
  })

  ethereum.on('disconnect', () => {
    eventEmitter.emit('disconnect')
  })
}

export function getProvider () {
  return ethereum
}

export function isMetamaskInstalled () {
  return !!ethereum && ethereum.isMetaMask
}

export async function getAddress () {
  if (isMetamaskInstalled()) {
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
    return toChecksumAddress(accounts[0])
  } else {
    return null
  }
}

export async function getChainId () {
  if (isMetamaskInstalled()) {
    const chainId = await ethereum.request({ method: 'net_version' })
    return parseInt(chainId)
  } else {
    return null
  }
}

export async function addChain (chainId) {
  try {
    if (BLOCKCHAIN_ETHEREUM === chainId) {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x' + chainId.toString(16) }],
      })
    }
    if ([BLOCKCHAIN_BINANCE, BLOCKCHAIN_POLYGON].includes(chainId)) {
      const prefixChainName = process.env.NODE_ENV === 'development' ? ' Testnet' : ''
      const params = [{
        chainId: '0x' + chainId.toString(16),
        chainName: NAME_BY_BLOCKCHAIN[chainId] + prefixChainName,
        nativeCurrency: {
          name: NATIVE_CURRENCY_BY_BLOCKCHAIN[chainId],
          symbol: NATIVE_CURRENCY_BY_BLOCKCHAIN[chainId],
          decimals: 18,
        },
        rpcUrls: [PROVIDER_BY_CHAIN_ID[chainId]],
        blockExplorerUrls: [EXPLORER_BY_BLOCKCHAIN[chainId]],
      }]
      await ethereum.request({
        method: 'wallet_addEthereumChain',
        params,
      })
    }
  } catch (err) {
    console.error('44', err)
  }
}
