<div class="pending">
  <div class="container">
    <fieldset v-if="transactions === null && isActiveWallet">
      <legend>Pending Transactions</legend>
      <div class="initial-loader-wrapper">
        <loader class="initial-loader"></loader>
      </div>
    </fieldset>
    <fieldset v-else>
      <legend>Pending Transactions</legend>
      <div v-if="pendingTransactions.length > 0" class="transactions">
        <div class="row labels">
          <div class="tokens">Tokens</div>
          <div class="from">From</div>
          <div class="to">To</div>
          <div class="action"></div>
        </div>
        <div v-for="transaction in pendingTransactions" :key="transaction.id" class="row transaction">
          <div class="tokens"><span class="name">Amount: </span>{{ formatTime(transaction) }} {{transaction.currencyFrom.name}}</div>
          <div class="from">
            <span class="name">From: </span>
            <div class="network">
              <img :src="getNetworkIcon(transaction.fromChainId)" :alt="getNetworkName(transaction.from)" />
              <span>{{ getNetworkName(transaction.fromChainId) }}</span>
            </div>
          </div>
          <div class="to">
            <span class="name">To: </span>
            <div class="network">
              <img :src="getNetworkIcon(transaction.toChainId)" :alt="getNetworkName(transaction.to)" />
              <span>{{ getNetworkName(transaction.toChainId) }}</span>
            </div>
          </div>
          <div class="action">
            <template v-if="!transaction.loading">
              <div class="confirmations" v-if="!transaction.confirmed">
                Confirmations: {{ transaction.confirmations }}/{{ getMaxConfirmation(transaction.fromChainId) }}
              </div>
              <button
                @click="onClickClaim(transaction)"
                class="btn-claim"
                :disabled="!transaction.confirmed || claimedIds.includes(transaction.id)"
              >
                Claim
              </button>
            </template>
            <loader v-else></loader>
          </div>
        </div>
      </div>
      <div v-else class="empty">
        <img src="/static/images/swap/empty.svg" alt="Empty" />
        <div v-if="isActiveWallet">
          There are no pending transactions<br>If you just made a transaction, please wait a few seconds
        </div>
        <div v-else>
          To see the list of transactions, please, connect the wallet
        </div>
      </div>
    </fieldset>
  </div>
</div>
