import { mapActions, mapState, mapGetters } from 'vuex'
import AccountModal from '@/modals/Account/Account.vue'
import ConnectModal from '@/modals/Connect/Connect.vue'
import { BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_BINANCE, BLOCKCHAIN_POLYGON } from '@/constants/blockchain'
import { getCurrency } from '@/utils/currency'
import { format } from '@/utils/moneyFormat'
// import TxStatus from '@/modals/TxStatus/TxStatus.vue'

export default {
  computed: {
    ...mapState({
      walletChainIds: (state) => state.network.walletChainIds,
      walletAddress: (state) => state.network.walletAddress,
      balances: (state) => state.network.balances,
    }),
    ...mapGetters({
      isActiveWallet: 'network/isActiveWallet',
      isWrongNetwork: 'network/isWrongNetwork',
    }),
    isConnected () {
      return true
    },
    balanceFormattedCGU () {
      const blockchain = (this?.walletChainIds && 0 in this?.walletChainIds) ? this?.walletChainIds[0] : null
      const currency = getCurrency({
        name: 'CGU',
        blockchain, // TODO possible to do for all wallets in modal window
      })
      if (!currency) return '0.00'
      const formatted = format(
        this.balances[blockchain]?.[currency.address] || 0,
        {
          divider: currency?.baseUnits,
          toFixedNumber: currency?.digitsAfterDecimalShow,
        },
      )
      return formatted
    },
    balanceFormattedTIME () {
      const blockchain = (this?.walletChainIds && 0 in this?.walletChainIds) ? this?.walletChainIds[0] : null
      const currency = getCurrency({
        name: 'TIME',
        blockchain: blockchain, // TODO possible to do for all wallets in modal window
      })
      if (!currency) return '0.00'
      const formatted = format(
        this.balances[blockchain]?.[currency.address] || 0,
        {
          divider: currency?.baseUnits,
          toFixedNumber: currency?.digitsAfterDecimalShow,
        },
      )
      return formatted
    },
    wallet () {
      return '0xDb45215be6AC68657b74B63c778aF1df1f3Ef9f0'
    },
    walletFormatted () {
      return this.walletAddress
        ? `${this.walletAddress.substr(0, 6)}...${this.walletAddress.substr(-4)}`
        : null
    },
    isEthNetwork () {
      return this?.walletChainIds.includes(BLOCKCHAIN_ETHEREUM)
    },
    isBscNetwork () {
      return this?.walletChainIds.includes(BLOCKCHAIN_BINANCE)
    },
    isPolygonNetwork () {
      return this?.walletChainIds.includes(BLOCKCHAIN_POLYGON)
    },
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    onConnectClick () {
      this.openModal({
        component: () => ConnectModal,
      })
    },
    onAccountClick () {
      this.openModal({
        component: () => AccountModal,
      })
    },
  },
}
