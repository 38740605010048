import { mapActions, mapGetters, mapState } from 'vuex'
import {
  BLOCKCHAIN_BINANCE,
  BLOCKCHAIN_ETHEREUM,
  BLOCKCHAIN_POLYGON,
  WEB3_PROVIDER_METAMASK,
  WEB3_PROVIDER_WALLETCONNECT,
} from '@/constants/blockchain'

export default {
  computed: {
    ...mapState({
      userSelectedWallet: (state) => state.network.userSelectedWallet,
      walletAddress: (state) => state.network.walletAddress,
      walletChainIds: (state) => state.network.walletChainIds,
    }),
    ...mapGetters({
      isWrongNetwork: 'network/isWrongNetwork',
    }),
    walletFormatted () {
      return this.walletAddress
        ? `${this.walletAddress.substr(0, 6)}...${this.walletAddress.substr(-4)}`
        : null
    },
    metamaskSelected () {
      return this.userSelectedWallet === WEB3_PROVIDER_METAMASK
    },
    walletConnectSelected () {
      return this.userSelectedWallet === WEB3_PROVIDER_WALLETCONNECT
    },
    linkToEtherscan () {
      return `${process.env.VUE_APP_EXPLORER_ETHEREUM}/address/${this.walletAddress}`
    },
    linkToBscscan () {
      return `${process.env.VUE_APP_EXPLORER_BINANCE}/address/${this.walletAddress}`
    },
    linkToPolygonscan () {
      return `${process.env.VUE_APP_EXPLORER_POLYGON}/address/${this.walletAddress}`
    },
    isEthNetwork () {
      return this?.walletChainIds && this.walletChainIds.includes(BLOCKCHAIN_ETHEREUM)
    },
    isBscNetwork () {
      return this?.walletChainIds && this.walletChainIds.includes(BLOCKCHAIN_BINANCE)
    },
    isPolygonNetwork () {
      return this?.walletChainIds && this.walletChainIds.includes(BLOCKCHAIN_POLYGON)
    },
  },
  methods: {
    ...mapActions({
      closeModal: 'ui/closeModal',
      disconnectWallet: 'network/disconnectWallet',
    }),
    onCopyClick () {
      const textArea = document.createElement('textarea')
      textArea.value = this.walletAddress
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'
      textArea.style.opacity = '0'
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      document.execCommand('copy')
      document.body.removeChild(textArea)
    },
    async disconnect () {
      await this.disconnectWallet()
      this.closeModal()
    },
  },
}
