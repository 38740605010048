import { mapActions, mapGetters, mapState } from 'vuex'
import { format } from '@/utils/moneyFormat'
import { extractRevert } from '@/utils/transaction'
import Loader from '@/components/Loader/Loader.vue'
import TxStatus from '@/modals/TxStatus/TxStatus.vue'
import BadNetwork from '@/modals/BadNetwork/BadNetwork.vue'
import { BLOCKCHAIN_BINANCE, BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_POLYGON } from '@/constants/blockchain'
import { unlock } from '@/servicies/contracts/Locker'
import { mint } from '@/servicies/contracts/DerivativeToken'
import { getExplorerLink } from '@/utils/blockchain'
import { awaitTransactionStatusAppliedBlockchain } from '@/servicies/transaction'
import Deferred from 'promise-deferred'
import { TOKEN_CGU, TOKEN_TIME } from '@/constants/backend'

export default {
  components: {
    Loader
  },
  computed: {
    ...mapState({
      walletChainIds: (state) => state.network.walletChainIds,
      transactions: (state) => state.network.transactions,
      userSelectedWallet: (state) => state.network.userSelectedWallet,
    }),
    ...mapGetters({
      isActiveWallet: 'network/isActiveWallet',
      pendingTransactions: 'network/pendingTransactions',
    }),
  },
  data () {
    return {
      claiming: false,
      claimedIds: [],
      tx: null,
      errorMsg: null,
    }
  },
  methods: {
    ...mapActions({
      updateTransaction: 'network/updateTransaction',
      loadContractBalances: 'network/loadContractBalances',
      loadBalances: 'network/loadBalances',
      openModal: 'ui/openModal',
      closeModal: 'ui/closeModal',
    }),
    getNetworkIcon (chainId) {
      switch (+chainId) {
        case +process.env.VUE_APP_ETHEREUM_CHAIN_ID:
          return '/static/images/icon/eth.svg'
        case +process.env.VUE_APP_BINANCE_CHAIN_ID:
          return '/static/images/icon/bsc.svg'
        case +process.env.VUE_APP_POLYGON_CHAIN_ID:
          return '/static/images/icon/polygon.svg'
      }
    },
    getNetworkName (chainId) {
      switch (+chainId) {
        case +process.env.VUE_APP_ETHEREUM_CHAIN_ID:
          return 'Ethereum'
        case +process.env.VUE_APP_BINANCE_CHAIN_ID:
          return 'BNB Chain'
        case +process.env.VUE_APP_POLYGON_CHAIN_ID:
          return 'Polygon'
      }
    },
    getMaxConfirmation (chainId) {
      switch (+chainId) {
        case BLOCKCHAIN_ETHEREUM:
          return +process.env.VUE_APP_ETH_CONFIRMATIONS_VALUE
        case BLOCKCHAIN_BINANCE:
          return +process.env.VUE_APP_BSC_CONFIRMATIONS_VALUE
        case BLOCKCHAIN_POLYGON:
          return +process.env.VUE_APP_POLYGON_CONFIRMATIONS_VALUE
      }
    },
    getClaimDisabled (transaction) {
      return transaction.confirmations >= this.getMaxConfirmation(transaction.from) || this.claiming
    },
    async onClickClaim (transaction) {
      switch (transaction.token_id) {
        case TOKEN_TIME:
          await this.onClickClaimTime(transaction)
          break
        case TOKEN_CGU:
          await this.onClickClaimCgu(transaction)
          break
      }
    },
    async onClickClaimCgu (transaction) {
      try {
        this.claiming = true
        this.claimedIds.push(transaction.id)
        if (!this?.walletChainIds.includes(transaction.toChainId)) {
          try {
            const deferred = new Deferred()
            this.openModal({
              component: () => BadNetwork,
              props: {
                requiredChainId: transaction.toChainId,
                cbSelectRequired: () => {
                  deferred.resolve()
                },
                cbClose: () => {
                  deferred.reject()
                },
              },
            })
            await deferred.promise
          } catch (err) {
            console.error(err)
            return
          }
        }
        const from = transaction.signParams[0]
        const fromChainId = transaction.signParams[1]
        const actionId = transaction.signParams[3]
        const amount = transaction.signParams[4]

        if (transaction.toChainId === BLOCKCHAIN_BINANCE) {
          this.tx = await unlock({
            wallet: this.userSelectedWallet,
            token: TOKEN_CGU,
            from,
            fromChainId,
            burnId: actionId,
            amount,
            signatures: transaction.signatures,
          })
        }
        if ([BLOCKCHAIN_POLYGON, BLOCKCHAIN_ETHEREUM].includes(transaction.toChainId)) {
          this.tx = await mint({
            wallet: this.userSelectedWallet,
            token: TOKEN_CGU,
            blockchain: transaction.toChainId,
            from,
            fromChainId,
            lockId: actionId,
            amount,
            signatures: transaction.signatures,
          })
        }
        const link = getExplorerLink({
          blockchain: transaction.toChainId,
          tx: this.tx,
        })
        this.openModal({
          component: () => TxStatus,
          props: {
            type: 'process',
            title: 'Claiming...',
            text: `<a href="${link}" target="_blank">Transaction</a> successfully submitted to the blockchain, waiting to be mined`,
          },
        })
        await awaitTransactionStatusAppliedBlockchain({
          blockchain: transaction.toChainId,
          tx: this.tx,
        })
        await this.loadBalances(true)
        this.loadContractBalances()
        await this.updateTransaction((transaction.toChainId === BLOCKCHAIN_ETHEREUM ? ({ txIdUnlocked: this.tx }) : ({ txIdMinted: this.tx })))
        this.closeModal()
        const amountFormatted = format(amount, {
          divider: transaction.currencyTo.baseUnits,
          toFixedNumber: transaction.currencyTo?.digitsAfterDecimalShow,
        })
        this.openModal({
          component: () => TxStatus,
          props: {
            type: 'success',
            text: `Successfully Claimed ${amountFormatted} ${transaction.currencyTo.name}`,
          },
        })
      } catch (err) {
        this.errorMsg = extractRevert(err)
        this.closeModal()
        this.openModal({
          component: () => TxStatus,
          props: {
            type: 'error',
            text: this.errorMsg,
          },
        })
        console.error(err)
      } finally {
        this.claiming = false
        this.claimedIds = this.claimedIds.filter((item) => item !== transaction.id)
      }
    },
    async onClickClaimTime (transaction) {
      try {
        this.claiming = true
        this.claimedIds.push(transaction.id)
        if (!this?.walletChainIds.includes(transaction.toChainId)) {
          try {
            const deferred = new Deferred()
            this.openModal({
              component: () => BadNetwork,
              props: {
                requiredChainId: transaction.toChainId,
                cbSelectRequired: () => {
                  deferred.resolve()
                },
                cbClose: () => {
                  deferred.reject()
                },
              },
            })
            await deferred.promise
          } catch (err) {
            console.error(err)
            return
          }
        }
        const from = transaction.signParams[0]
        const fromChainId = transaction.signParams[1]
        const actionId = transaction.signParams[3]
        const amount = transaction.signParams[4]

        if (transaction.toChainId === BLOCKCHAIN_ETHEREUM) {
          this.tx = await unlock({
            wallet: this.userSelectedWallet,
            from,
            token: TOKEN_TIME,
            fromChainId,
            burnId: actionId,
            amount,
            signatures: transaction.signatures,
          })
        }
        if ([BLOCKCHAIN_BINANCE, BLOCKCHAIN_POLYGON].includes(transaction.toChainId)) {
          this.tx = await mint({
            wallet: this.userSelectedWallet,
            blockchain: transaction.toChainId,
            token: TOKEN_TIME,
            from,
            fromChainId,
            lockId: actionId,
            amount,
            signatures: transaction.signatures,
          })
        }
        const link = getExplorerLink({
          blockchain: transaction.toChainId,
          tx: this.tx,
        })
        this.openModal({
          component: () => TxStatus,
          props: {
            type: 'process',
            title: 'Claiming...',
            text: `<a href="${link}" target="_blank">Transaction</a> successfully submitted to the blockchain, waiting to be mined`,
          },
        })
        await awaitTransactionStatusAppliedBlockchain({
          blockchain: transaction.toChainId,
          tx: this.tx,
        })
        await this.loadBalances(true)
        this.loadContractBalances()
        await this.updateTransaction((transaction.toChainId === BLOCKCHAIN_ETHEREUM ? ({ txIdUnlocked: this.tx }) : ({ txIdMinted: this.tx })))
        this.closeModal()
        const amountFormatted = format(amount, {
          divider: transaction.currencyTo.baseUnits,
          toFixedNumber: transaction.currencyTo?.digitsAfterDecimalShow,
        })
        this.openModal({
          component: () => TxStatus,
          props: {
            type: 'success',
            text: `Successfully Claimed ${amountFormatted} ${transaction.currencyTo.name}`,
          },
        })
      } catch (err) {
        this.errorMsg = extractRevert(err)
        this.closeModal()
        this.openModal({
          component: () => TxStatus,
          props: {
            type: 'error',
            text: this.errorMsg,
          },
        })
        console.error(err)
      } finally {
        this.claiming = false
        this.claimedIds = this.claimedIds.filter((item) => item !== transaction.id)
      }
    },
    formatTime (transaction) {
      return format(transaction.amount, {
        divider: transaction.currencyFrom?.baseUnits,
        toFixedNumber: transaction.currencyFrom?.digitsAfterDecimalShow,
      })
    },
  },
}
