import { mapState, mapActions, mapMutations } from 'vuex'
import Multiselect from '@vueform/multiselect'
import BigNumber from 'bignumber.js'
import uniqueId from 'lodash/uniqueId'
import { BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_BINANCE, BLOCKCHAIN_POLYGON } from '@/constants/blockchain'
import { getWeb3Async } from '@/servicies/web3'
import { getCurrency } from '@/utils/currency'
import { format } from '@/utils/moneyFormat'
import { extractRevert } from '@/utils/transaction'
import { getContractAsync } from '@/servicies/contracts/Locker'
import { transferAndCall } from '@/servicies/contracts/Erc20'
import { awaitTransactionStatusAppliedBlockchain } from '@/servicies/transaction'
import { getConfirmationsCountByBlockchain, getExplorerLink } from '@/utils/blockchain'
import TxStatus from '@/modals/TxStatus/TxStatus.vue'
import BackendTransaction from '@/models/BackendTransaction'
import { burn } from '@/servicies/contracts/DerivativeToken'
import Deferred from 'promise-deferred'
import BadNetwork from '@/modals/BadNetwork/BadNetwork.vue'
import {
  EVENT_BURNED,
  EVENT_LOCKED, getCurrencyNameByToken,
  TOKEN_CGU,
  TOKEN_TIME,
} from '@/constants/backend'
import '@vueform/multiselect/themes/default.css'

const TOKEN_OPTIONS = [
  {
    label: 'TIME',
    value: TOKEN_TIME,
  },
  {
    label: 'CGU',
    value: TOKEN_CGU,
  },
]

const TIME_ALLOWED_CURRENCIES = [
  {
    label: 'Ethereum',
    value: BLOCKCHAIN_ETHEREUM,
  },
  {
    label: 'BNB Chain',
    value: BLOCKCHAIN_BINANCE,
  },
  {
    label: 'Polygon',
    value: BLOCKCHAIN_POLYGON,
  },
]

const CGU_ALLOWED_NETWORKS = [
  {
    label: 'BNB Chain',
    value: BLOCKCHAIN_BINANCE,
  },
  {
    label: 'Ethereum',
    value: BLOCKCHAIN_ETHEREUM,
  },
  {
    label: 'Polygon',
    value: BLOCKCHAIN_POLYGON,
  },
]

export default {
  components: {
    Multiselect,
  },
  mounted () {
    this.onClickMax()
  },
  computed: {
    ...mapState({
      walletChainIds: (state) => state.network.walletChainIds,
      walletAddress: (state) => state.network.walletAddress,
      userSelectedWallet: (state) => state.network.userSelectedWallet,
      transactions: (state) => state.network.transactions,
      balances: (state) => state.network.balances,
    }),
    currency () {
      return getCurrency({
        name: getCurrencyNameByToken(this.token),
        blockchain: this.fromNetwork,
      })
    },
    balanceBase () {
      return this.balances[this.fromNetwork]?.[this.currency.address] || 0
    },
    balanceFormatted () {
      const formatted = format(
        this.balanceBase,
        {
          divider: this.currency?.baseUnits,
          toFixedNumber: this.currency?.digitsAfterDecimalShow,
        },
      )
      return !this.currency || !formatted ? '0.00' : formatted
    },
    amountBase () {
      return new BigNumber(this.amount.toString()).multipliedBy(this.currency.baseUnits)
    },
    amountFormatted () {
      const formatted = format(
        this.amountBase,
        {
          divider: this.currency?.baseUnits,
          toFixedNumber: this.currency?.digitsAfterDecimalShow,
        },
      )
      return !this.currency || !formatted ? '0.00' : formatted
    },
    maxBalance () {
      return !this.currency
        ? 0
        : new BigNumber(this.balanceBase).dividedBy(this.currency.baseUnits).toFixed(8)
    },
    formFieldsDisabled () {
      return new BigNumber(this.balanceBase).isEqualTo(0) || this.swapping || this.fromNetwork === this.toNetwork
    },
    nextNetwork () {
      switch (this.token) {
        case TOKEN_TIME:
          return {
            [BLOCKCHAIN_ETHEREUM]: BLOCKCHAIN_BINANCE,
            [BLOCKCHAIN_BINANCE]: BLOCKCHAIN_POLYGON,
            [BLOCKCHAIN_POLYGON]: BLOCKCHAIN_ETHEREUM,
          }
        case TOKEN_CGU:
          return {
            [BLOCKCHAIN_ETHEREUM]: BLOCKCHAIN_BINANCE,
            [BLOCKCHAIN_BINANCE]: BLOCKCHAIN_POLYGON,
            [BLOCKCHAIN_POLYGON]: BLOCKCHAIN_ETHEREUM,
          }
      }
    },
    currencyOptions () {
      switch (this.token) {
        case TOKEN_TIME:
          return TIME_ALLOWED_CURRENCIES
        case TOKEN_CGU:
          return CGU_ALLOWED_NETWORKS
      }
    },
  },
  data () {
    return {
      forwardDirection: true,
      fromNetwork: BLOCKCHAIN_ETHEREUM,
      toNetwork: BLOCKCHAIN_BINANCE,
      swapping: false,
      tx: null,
      errorMsg: null,
      amount: 0,
      token: TOKEN_OPTIONS[0].value,
      BLOCKCHAIN_ETHEREUM,
      BLOCKCHAIN_BINANCE,
      BLOCKCHAIN_POLYGON,
      TOKEN_OPTIONS,
    }
  },
  methods: {
    ...mapMutations({
      setTransaction: 'network/setTransaction',
    }),
    ...mapActions({
      updateTransaction: 'network/updateTransaction',
      loadBalances: 'network/loadBalances',
      loadContractBalances: 'network/loadContractBalances',
      openModal: 'ui/openModal',
      closeModal: 'ui/closeModal',
    }),
    onSwitchClick () {
      this.forwardDirection = !this.forwardDirection
      const fromSaved = this.fromNetwork
      this.fromNetwork = this.toNetwork
      this.toNetwork = fromSaved
    },
    changeToken (val) {
      if (this.token !== val) {
        if (val === TOKEN_CGU) {
          const foundFrom = CGU_ALLOWED_NETWORKS.some((item) => item.value === this.fromNetwork)
          const foundTo = CGU_ALLOWED_NETWORKS.some((item) => item.value === this.toNetwork)
          if (!foundFrom || !foundTo) {
            this.fromNetwork = BLOCKCHAIN_BINANCE
            this.toNetwork = BLOCKCHAIN_ETHEREUM
          }
        }
        this.token = val
      }
    },
    changeFromNetwork (val) {
      this.fromNetwork = val
      if (this.fromNetwork === this.toNetwork) {
        this.toNetwork = this.nextNetwork[this.fromNetwork]
      }
    },
    changeToNetwork (val) {
      this.toNetwork = val
      if (this.fromNetwork === this.toNetwork) {
        this.fromNetwork = this.nextNetwork[this.toNetwork]
      }
    },
    onClickMax () {
      if (this.formFieldsDisabled) return
      const formatted = format(
        this.balanceBase,
        {
          divider: this.currency?.baseUnits,
          toFixedNumber: this.currency?.decimals,
          withCommas: false,
        },
      )
      this.amount = formatted
    },
    async swap () {
      switch (this.token) {
        case TOKEN_TIME:
          await this.swapTIME()
          break
        case TOKEN_CGU:
          await this.swapCGU()
          break
      }
    },
    async swapCGU () {
      if (this.formFieldsDisabled) return
      if (!this?.walletChainIds.includes(this.fromNetwork)) {
        try {
          const deferred = new Deferred()
          this.openModal({
            component: () => BadNetwork,
            props: {
              requiredChainId: this.fromNetwork,
              cbSelectRequired: () => {
                deferred.resolve()
              },
              cbClose: () => {
                deferred.reject()
              },
            },
          })
          await deferred.promise
        } catch (err) {
          console.error(err)
          return
        }
      }
      try {
        this.swapping = true
        this.tx = null
        const contractTimeLocker = await getContractAsync(this.token)
        const web3 = await getWeb3Async({ blockchain: this.fromNetwork })
        const data = web3.eth.abi.encodeParameters(['uint256'], [this.toNetwork])
        if (this.fromNetwork === BLOCKCHAIN_BINANCE) {
          const txParams = {
            wallet: this.userSelectedWallet,
            blockchain: this.fromNetwork,
            contractAddress: this.currency.address,
            from: this.walletAddress,
            to: contractTimeLocker._address,
            amount: this.amountBase.toFixed(0),
            data,
          }
          this.tx = await transferAndCall(txParams)
        }
        if ([BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_POLYGON].includes(this.fromNetwork)) {
          const txParams = {
            wallet: this.userSelectedWallet,
            token: this.token,
            blockchain: this.fromNetwork,
            from: this.walletAddress,
            toChainId: this.toNetwork,
            amount: this.amountBase.toFixed(0),
          }
          this.tx = await burn(txParams)
        }
        const link = getExplorerLink({
          blockchain: this.fromNetwork,
          tx: this.tx,
        })
        this.openModal({
          component: () => TxStatus,
          props: {
            type: 'process',
            title: 'Swapping...',
            text: `<a href="${link}" target="_blank">Transaction</a> successfully submitted to the blockchain, waiting to be mined`,
          },
        })
        await awaitTransactionStatusAppliedBlockchain({
          blockchain: this.fromNetwork,
          tx: this.tx,
        })
        const foundIndex = this.transactions
          .findIndex((item) => [item.txid_lock, item.txid_mint, item.txid_burn, item.txid_unlock].includes(this.tx))
        if (foundIndex === -1) {
          const txIdSnakeCase = this.fromNetwork === BLOCKCHAIN_BINANCE ? { txid_lock: this.tx } : { txid_burn: this.tx }
          const event = this.fromNetwork === BLOCKCHAIN_BINANCE ? EVENT_LOCKED : EVENT_BURNED
          const tempTransaction = new BackendTransaction({
            id: 10000000 + uniqueId(),
            token_id: this.token,
            loading: true,
            ...txIdSnakeCase,
            sender: this.walletAddress,
            type: BackendTransaction.getTransactionType(this.fromNetwork, this.toNetwork),
            validators: [{
              event,
              amount: this.amountBase.toFixed(0),
              confirmations: 0,
              txid: this.tx,
            }],
          })
          await this.setTransaction({
            txId: this.tx,
            transaction: tempTransaction,
          })
        }
        const txIdCamelCase = this.fromNetwork === BLOCKCHAIN_BINANCE ? ({ txIdLocked: this.tx }) : ({ txIdBurned: this.tx })
        const actionName = this.fromNetwork === BLOCKCHAIN_BINANCE ? 'Lock' : 'Burn'
        await this.loadBalances(true)
        this.loadContractBalances()
        await this.updateTransaction(txIdCamelCase)
        this.closeModal()
        const confirmations = getConfirmationsCountByBlockchain(this.fromNetwork)
        this.openModal({
          component: () => TxStatus,
          props: {
            type: 'success',
            text:
              `Successfully ${actionName} ${this.amountFormatted} TIME<br>Please claim your tokens after ${confirmations} confirmations`,
          },
        })
        this.amount = 0
      } catch (err) {
        if (err.code === -32602) {
          this.errorMsg = 'Please, make sure you\'re using the latest version of Metamask'
        } else {
          this.errorMsg = extractRevert(err)
        }
        this.closeModal()
        this.openModal({
          component: () => TxStatus,
          props: {
            type: 'error',
            text: this.errorMsg,
          },
        })
        console.error(err)
      } finally {
        this.swapping = false
      }
    },
    async swapTIME () {
      if (this.formFieldsDisabled) return
      if (!this?.walletChainIds.includes(this.fromNetwork)) {
        try {
          const deferred = new Deferred()
          this.openModal({
            component: () => BadNetwork,
            props: {
              requiredChainId: this.fromNetwork,
              cbSelectRequired: () => {
                deferred.resolve()
              },
              cbClose: () => {
                deferred.reject()
              },
            },
          })
          await deferred.promise
        } catch (err) {
          console.error(err)
          return
        }
      }
      try {
        this.swapping = true
        this.tx = null
        const contractTimeLocker = await getContractAsync()
        const web3 = await getWeb3Async({ blockchain: this.fromNetwork })
        const data = web3.eth.abi.encodeParameters(['uint256'], [this.toNetwork])
        if (this.fromNetwork === BLOCKCHAIN_ETHEREUM) {
          this.tx = await transferAndCall({
            wallet: this.userSelectedWallet,
            blockchain: this.fromNetwork,
            contractAddress: this.currency.address,
            from: this.walletAddress,
            to: contractTimeLocker._address,
            amount: this.amountBase.toFixed(0),
            data,
          })
        }
        if ([BLOCKCHAIN_BINANCE, BLOCKCHAIN_POLYGON].includes(this.fromNetwork)) {
          this.tx = await burn({
            wallet: this.userSelectedWallet,
            blockchain: this.fromNetwork,
            from: this.walletAddress,
            toChainId: this.toNetwork,
            amount: this.amountBase.toFixed(0),
          })
        }
        const link = getExplorerLink({
          blockchain: this.fromNetwork,
          tx: this.tx,
        })
        this.openModal({
          component: () => TxStatus,
          props: {
            type: 'process',
            title: 'Swapping...',
            text: `<a href="${link}" target="_blank">Transaction</a> successfully submitted to the blockchain, waiting to be mined`,
          },
        })
        await awaitTransactionStatusAppliedBlockchain({
          blockchain: this.fromNetwork,
          tx: this.tx,
        })
        const foundIndex = this.transactions
          .findIndex((item) => [item.txid_lock, item.txid_mint, item.txid_burn, item.txid_unlock].includes(this.tx))
        if (foundIndex === -1) {
          const tempTransaction = new BackendTransaction({
            id: 10000000 + uniqueId(),
            loading: true,
            ...(this.fromNetwork === BLOCKCHAIN_ETHEREUM ? { txid_lock: this.tx } : { txid_burn: this.tx }),
            sender: this.walletAddress,
            type: BackendTransaction.getTransactionType(this.fromNetwork, this.toNetwork),
            validators: [{
              event: this.fromNetwork === BLOCKCHAIN_ETHEREUM ? EVENT_LOCKED : EVENT_BURNED,
              amount: this.amountBase.toFixed(0),
              confirmations: 0,
              txid: this.tx,
            }],
          })
          await this.setTransaction({
            txId: this.tx,
            transaction: tempTransaction,
          })
        }
        await this.loadBalances(true)
        this.loadContractBalances()
        await this.updateTransaction((this.fromNetwork === BLOCKCHAIN_ETHEREUM ? ({ txIdLocked: this.tx }) : ({ txIdBurned: this.tx })))
        this.closeModal()
        const actionName = this.fromNetwork === BLOCKCHAIN_ETHEREUM ? 'Lock' : 'Burn'
        const confirmations = getConfirmationsCountByBlockchain(this.fromNetwork)
        this.openModal({
          component: () => TxStatus,
          props: {
            type: 'success',
            text:
              `Successfully ${actionName} ${this.amountFormatted} TIME<br>Please claim your tokens after ${confirmations} confirmations`,
          },
        })
        this.amount = 0
      } catch (err) {
        if (err.code === -32602) {
          this.errorMsg = 'Please, make sure you\'re using the latest version of Metamask'
        } else {
          this.errorMsg = extractRevert(err)
        }
        this.closeModal()
        this.openModal({
          component: () => TxStatus,
          props: {
            type: 'error',
            text: this.errorMsg,
          },
        })
        console.error(err)
      } finally {
        this.swapping = false
      }
    },
  },
}
