<div class="swap">
  <img src="/static/images/swap/bg.svg" alt="Bridge" class="bg" />
  <div class="container">
    <div class="img-wrap">
      <img src="/static/images/swap/swap.svg" alt="Swap TIME" />
    </div>
    <div class="swap-content">
      <div class="before-background">
      </div>
      <form @submit.prevent="swap">
        <div class="swap-form">
          <div class="after-background">
          </div>
          <div class="network network-from">
            <div class="item-direction-and-balance">
              <span class="from-text">From</span>
              <span class="balance" @click="onClickMax" :class="{'max-btn-disabled': formFieldsDisabled}">
                {{ balanceFormatted }} {{currency.name}}
              </span>
            </div>
            <div class="selects">
              <div class="item-selected-network select-token-wrapper">
                <Multiselect
                  :modelValue="token"
                  @update:modelValue="changeToken"
                  :canClear="false"
                  :canDeselect="false"
                  :options="TOKEN_OPTIONS"
                />
              </div>
              <div class="item-selected-network">
                <Multiselect
                  :modelValue="fromNetwork"
                  @update:modelValue="changeFromNetwork"
                  :canClear="false"
                  :canDeselect="false"
                  :options="currencyOptions"
                />
              </div>
            </div>
            <div class="item-amount">
              <input
                :disabled="formFieldsDisabled"
                name="amount"
                :max="maxBalance"
                step="0.00000001"
                min="0.00000001"
                v-model="amount"
                type="number"
                required
              />
            </div>

            <div class="elements" :class="{ 'rotated': !forwardDirection }">
              <img v-if="(forwardDirection ? fromNetwork : toNetwork) === BLOCKCHAIN_ETHEREUM"
                   class="network-icon network-eth"
                   src="/static/images/icon/eth.svg" alt="Ethereum Network" />
              <img v-if="(forwardDirection ? fromNetwork : toNetwork) === BLOCKCHAIN_BINANCE" class="network-icon network-bsc"
                   src="/static/images/icon/bsc.svg" alt="BNB Chain Network" />
              <img v-if="(forwardDirection ? fromNetwork : toNetwork) === BLOCKCHAIN_POLYGON" class="network-icon network-polygon"
                   src="/static/images/icon/polygon.svg" alt="Polygon Network" />
              <button class="btn-switch" @click.prevent="onSwitchClick">
                <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M30.6667 24.9167L38.3334 32.5834L30.6667 40.25" stroke="white" stroke-width="3"
                        stroke-linecap="round" />
                  <path d="M38.3333 32.5833H9.58331" stroke="white" stroke-width="3" stroke-linecap="round" />
                  <path d="M15.3333 5.75L7.66665 13.4167L15.3333 21.0833" stroke="white" stroke-width="3"
                        stroke-linecap="round" />
                  <path d="M7.66669 13.4167H36.4167" stroke="white" stroke-width="3" stroke-linecap="round" />
                </svg>
              </button>
              <img v-if="(!forwardDirection ? fromNetwork : toNetwork) === BLOCKCHAIN_ETHEREUM"
                   class="network-icon network-eth"
                   src="/static/images/icon/eth.svg" alt="Ethereum Network" />
              <img v-if="(!forwardDirection ? fromNetwork : toNetwork) === BLOCKCHAIN_BINANCE"
                   class="network-icon network-bsc"
                   src="/static/images/icon/bsc.svg" alt="BNB Chain Network" />
              <img v-if="(!forwardDirection ? fromNetwork : toNetwork) === BLOCKCHAIN_POLYGON"
                   class="network-icon polygon"
                   src="/static/images/icon/polygon.svg" alt="Polygon Network" />
            </div>
          </div>
          <div class="network network-to">
            <div class="item-direction-and-balance">
              <span>To</span>
            </div>

            <div class="item-selected-network item-selected-network-to">
              <Multiselect
                :modelValue="toNetwork"
                @update:modelValue="changeToNetwork"
                :canDeselect="false"
                :canClear="false"
                :options="currencyOptions"
              />
            </div>
            <div class="item-amount item-amount-to">
              <div class="total">{{ amount }}</div>
            </div>
          </div>
        </div>
        <button class="btn-swap" :class="{ 'btn-swap-disabled': formFieldsDisabled }" type="submit">
          SWAP
        </button>
      </form>

    </div>
  </div>
</div>
