export const TRANSACTION_TYPE_ETH_TO_BSC = 1
export const TRANSACTION_TYPE_BSC_TO_ETH = 2
export const TRANSACTION_TYPE_ETH_TO_POLYGON = 3
export const TRANSACTION_TYPE_POLYGON_TO_ETH = 4
export const TRANSACTION_TYPE_BSC_TO_POLYGON = 5
export const TRANSACTION_TYPE_POLYGON_TO_BSC = 6

export const TOKEN_TIME = 1
export const TOKEN_CGU = 2

export function getCurrencyNameByToken (token) {
  switch (token) {
    case TOKEN_TIME: return 'TIME'
    case TOKEN_CGU: return 'CGU'
  }
}

export const TRANSACTION_STATUS_LOCKED = 1
export const TRANSACTION_STATUS_MINTED = 2
export const TRANSACTION_STATUS_BURNED = 3
export const TRANSACTION_STATUS_UNLOCKED = 4

export const EVENT_LOCKED = 'Locked'
export const EVENT_UNLOCKED = 'Unlocked'
export const EVENT_MINTED = 'Minted'
export const EVENT_BURNED = 'Burned'
