import { getWeb3Async } from '@/servicies/web3'
import { waitTxFromMetamask } from '@/utils/blockchain'
import {
  BLOCKCHAIN_BINANCE,
  BLOCKCHAIN_ETHEREUM,
  WEB3_PROVIDER_METAMASK,
} from '@/constants/blockchain'
import { signAndSend } from '@/servicies/transaction'
import { TOKEN_CGU, TOKEN_TIME } from '@/constants/backend'

const contract = {
  [TOKEN_TIME]: null,
  [TOKEN_CGU]: null
}
const artifacts = {
  [TOKEN_TIME]: null,
  [TOKEN_CGU]: null
}

export async function getArtifactsAsync (token = TOKEN_TIME) {
  if (!artifacts[token]) {
    if (token === TOKEN_TIME) {
      artifacts[token] = await import(/* webpackChunkName: 'time-locker-artifacts' */ 'timebridge-sc-artifacts/artifacts/TimeLocker.json')
    }
    if (token === TOKEN_CGU) {
      artifacts[token] = await import(/* webpackChunkName: 'cgu-locker-artifacts' */ 'timebridge-sc-artifacts/artifacts/CguLocker.json')
    }
  }
  return artifacts[token]
}

export async function getContractAsync (token = TOKEN_TIME) {
  const blockchain = token === TOKEN_TIME ? BLOCKCHAIN_ETHEREUM : BLOCKCHAIN_BINANCE
  const web3 = await getWeb3Async({ blockchain })
  const artifacts = await getArtifactsAsync(token)
  if (!contract[token]) {
    contract[token] = new web3.eth.Contract(artifacts.abi, artifacts.networks[blockchain].address)
  }
  return contract[token]
}

export async function lock (
  {
    wallet = WEB3_PROVIDER_METAMASK,
    token = TOKEN_TIME,
    from,
    toChainId,
    amount,
  },
) {
  const contract = await getContractAsync(token)
  const encodedAbi = contract.methods.lock(toChainId, amount).encodeABI()
  const eventEmitter = await signAndSend({
    wallet,
    from,
    to: contract.options.address,
    encodedAbi,
  })
  return waitTxFromMetamask(eventEmitter)
}

export async function unlock (
  {
    wallet = WEB3_PROVIDER_METAMASK,
    token = TOKEN_TIME,
    from,
    fromChainId,
    burnId,
    amount,
    signatures,
  },
) {
  const contract = await getContractAsync(token)
  const encodedAbi = contract.methods.unlock(fromChainId, burnId, amount, signatures).encodeABI()
  const eventEmitter = await signAndSend({
    wallet,
    from,
    to: contract.options.address,
    encodedAbi,
  })
  return waitTxFromMetamask(eventEmitter)
}
