import { mapActions, mapState } from 'vuex'
import { isMetamaskInstalled } from '@/servicies/metamask'
import TxStatus from '@/modals/TxStatus/TxStatus.vue'
import {
  WEB3_PROVIDER_METAMASK,
  WEB3_PROVIDER_WALLETCONNECT,
} from '@/constants/blockchain'
import SelectNetwork from '@/modals/SelectNetwork/SelectNetwork.vue'

export default {
  computed: {
    ...mapState({
      walletConnected: (state) => state.network.walletConnected,
    }),
  },
  created () {
    this.$watch(
      'walletConnected',
      (newVal, oldVal) => {
        if (newVal && !oldVal) {
          this.closeModal()
        }
      },
    )
  },
  data () {
    return {
      WEB3_PROVIDER_METAMASK,
      WEB3_PROVIDER_WALLETCONNECT,
    }
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      closeModal: 'ui/closeModal',
      connectToWallet: 'network/connect',
    }),
    async connect (wallet = WEB3_PROVIDER_METAMASK) {
      if (wallet === WEB3_PROVIDER_METAMASK) {
        if (!isMetamaskInstalled()) {
          this.openModal({
            component: () => TxStatus,
            props: {
              type: 'error',
              text: `Metamask is not installed in order to install go to the site
<a href="https://metamask.io" target="_blank" rel="noopener">https://metamask.io</a>
`,
            },
          })
          return
        }
        await this.connectToWallet({
          wallet: WEB3_PROVIDER_METAMASK
        })
        this.closeModal()
      }
      if (wallet === WEB3_PROVIDER_WALLETCONNECT) {
        this.openModal({
          component: () => SelectNetwork,
          props: {
            onSelectNetworkCb: (networks) => {
              this.connectToWallet({ wallet, networks })
              this.closeModal()
            }
          }
        })
      }
    },
  },
}
