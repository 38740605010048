import { mapGetters } from 'vuex'
import { format } from '@/utils/moneyFormat'
import { getExplorerLink } from '@/utils/blockchain'

export default {
  computed: {
    ...mapGetters({
      historyTransactions: 'network/historyTransactions',
    }),
  },
  methods: {
    getNetworkIcon (chainId) {
      switch (+chainId) {
        case +process.env.VUE_APP_ETHEREUM_CHAIN_ID:
          return '/static/images/icon/eth.svg'
        case +process.env.VUE_APP_BINANCE_CHAIN_ID:
          return '/static/images/icon/bsc.svg'
        case +process.env.VUE_APP_POLYGON_CHAIN_ID:
          return '/static/images/icon/polygon.svg'
      }
    },
    getNetworkName (chainId) {
      switch (+chainId) {
        case +process.env.VUE_APP_ETHEREUM_CHAIN_ID:
          return 'Ethereum'
        case +process.env.VUE_APP_BINANCE_CHAIN_ID:
          return 'BNB Chain'
        case +process.env.VUE_APP_POLYGON_CHAIN_ID:
          return 'Polygon'
      }
    },
    formatTime (transaction) {
      return format(transaction.amount, {
        divider: transaction.currencyFrom?.baseUnits,
        toFixedNumber: transaction.currencyFrom?.digitsAfterDecimalShow,
      })
    },
    formatTxId (tx) {
      return tx ? `${tx.substr(0, 7)}...${tx.substr(-5)}` : null
    },
    getLink (blockchain, tx) {
      return getExplorerLink({ blockchain, tx })
    },
  },
}
