import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import ui from './ui'
import network from './network'
import { getRates } from '@/api/backend'

const dataState = createPersistedState({
  paths: [
    'network.userSelectedWallet',
    'network.walletConnected',
    'network.walletConnectNetwork',
  ]
})

export default createStore({
  strict: false,
  state: {
    timePrice: null,
  },
  getters: {},
  mutations: {
    setTimePrice (state, price) {
      state.timePrice = price
    },
  },
  actions: {
    async loadRate ({ commit }) {
      try {
        const { data } = await getRates()
        const priceTime = data.result?.find(item => item.currency === 'TIME')?.price
        commit('setTimePrice', priceTime)
      } catch (e) {
        console.error(e)
      }
    },
  },
  modules: {
    ui: ui(),
    network: network(),
  },
  plugins: [dataState],
})
