<bg-modal class="root connect-modal">
  <h3 class="modal-title">Connect to Wallet</h3>
  <div class="content">
    <div class="wallets">
      <div class="row wallet" @click="connect(WEB3_PROVIDER_METAMASK)">
        <img class="wallet-logo" src="/static/images/wallets/metamask.svg" />
        <span class="text">Metamask</span>
      </div>
      <div class="row wallet" @click="connect(WEB3_PROVIDER_WALLETCONNECT)">
        <img class="wallet-logo" src="/static/images/wallets/walletconnect.svg" />
        <span class="text">WalletConnect</span>
      </div>
    </div>
  </div>
</bg-modal>
