import Button from './Button/Button.vue'
import Icon from './Icon/Icon.vue'
import Loader from './Loader/Loader.vue'
import Modal from './Modal/Modal.vue'
import Skeleton from './Skeleton/Skeleton.vue'

const components = [Button, Icon, Loader, Modal, Skeleton]

export default {
  install: (Vue) => {
    for (const component of components) {
      if (component.name) {
        Vue.component(component.name, component)
      }
    }
  },
}
