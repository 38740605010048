import { mapActions, mapState } from 'vuex'
import { NAME_BY_BLOCKCHAIN, WEB3_PROVIDER_METAMASK, WEB3_PROVIDER_WALLETCONNECT } from '@/constants/blockchain'
import { addChain } from '@/servicies/metamask'

export default {
  props: {
    requiredChainId: Number,
    cbSelectRequired: Function,
    cbClose: Function,
  },
  computed: {
    ...mapState({
      walletChainIds: (state) => state.network.walletChainIds,
      userSelectedWallet: (state) => state.network.userSelectedWallet,
    }),
    requiredNetworkName () {
      return NAME_BY_BLOCKCHAIN[this.requiredChainId]
    },
    isBadNetwork () {
      return !(this?.walletChainIds && this.walletChainIds.includes(this.requiredChainId))
    },
    isMetamask () {
      return this.userSelectedWallet === WEB3_PROVIDER_METAMASK
    },
    isWalletConnect () {
      return this.userSelectedWallet === WEB3_PROVIDER_WALLETCONNECT
    }
  },
  created () {
    if (this.isMetamask) {
      addChain(this.requiredChainId)
    }
  },
  data () {
    return {
      connectingWalletConnect: false,
    }
  },
  methods: {
    ...mapActions({
      closeModal: 'ui/closeModal',
      disconnectWallet: 'network/disconnectWallet',
      connect: 'network/connect',
    }),
    async clickReconnect () {
      if (this.connectingWalletConnect) return
      this.connectingWalletConnect = true
      await this.disconnectWallet()
      setTimeout(async () => {
        try {
          await this.connect({ wallet: WEB3_PROVIDER_WALLETCONNECT, networks: [this.requiredChainId] })
        } catch (err) {
          console.error(err)
        } finally {
          this.connectingWalletConnect = false
        }
      }, 1000)
    },
    clickContinue () {
      this.cbSelectRequired()
      this.closeModal()
    },
    close () {
      if (this.cbClose) this.cbClose()
    },
  },
}
