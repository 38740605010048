import { getWeb3Async } from '@/servicies/web3'
import { waitTxFromMetamask } from '@/utils/blockchain'
import {
  BLOCKCHAIN_BINANCE,
  BLOCKCHAIN_ETHEREUM,
  BLOCKCHAIN_POLYGON,
  WEB3_PROVIDER_METAMASK,
} from '@/constants/blockchain'
import { signAndSend } from '@/servicies/transaction'
import { TOKEN_CGU, TOKEN_TIME } from '@/constants/backend'

const contract = {
  [TOKEN_TIME]: {
    [BLOCKCHAIN_BINANCE]: null,
    [BLOCKCHAIN_POLYGON]: null,
  },
  [TOKEN_CGU]: {
    [BLOCKCHAIN_ETHEREUM]: null,
    [BLOCKCHAIN_POLYGON]: null,
  },

}
const artifacts = {
  [TOKEN_TIME]: {
    [BLOCKCHAIN_BINANCE]: null,
    [BLOCKCHAIN_POLYGON]: null,
  },
  [TOKEN_CGU]: {
    [BLOCKCHAIN_ETHEREUM]: null,
    [BLOCKCHAIN_POLYGON]: null,
  },
}

export async function getContractAsync ({
  blockchain = BLOCKCHAIN_BINANCE,
  token = TOKEN_TIME,
}) {
  const web3 = await getWeb3Async({ blockchain })
  switch (token) {
    case TOKEN_TIME:
      if (blockchain === BLOCKCHAIN_BINANCE) {
        artifacts[token][blockchain] = await import(/* webpackChunkName: 'timebridge-sc-artifacts/bsc-time-token' */
          'timebridge-sc-artifacts/artifacts/BSCTimeToken.json')
      }
      if (blockchain === BLOCKCHAIN_POLYGON) {
        artifacts[token][blockchain] = await import(/* webpackChunkName: 'timebridge-sc-artifacts/polygon-time-token' */
          'timebridge-sc-artifacts/artifacts/PolygonTimeToken.json')
      }
      break
    case TOKEN_CGU:
      if (blockchain === BLOCKCHAIN_ETHEREUM) {
        artifacts[token][blockchain] = await import(/* webpackChunkName: 'timebridge-sc-artifacts/eth-cgu-token' */
          'timebridge-sc-artifacts/artifacts/ETHCguToken.json')
      }
      if (blockchain === BLOCKCHAIN_POLYGON) {
        artifacts[token][blockchain] = await import(/* webpackChunkName: 'timebridge-sc-artifacts/polygon-cgu-token' */
          'timebridge-sc-artifacts/artifacts/PolygonCguToken.json')
      }
      break
  }
  if (!contract[token][blockchain]) {
    contract[token][blockchain] = new web3.eth.Contract(
      artifacts[token][blockchain].abi,
      artifacts[token][blockchain].networks[blockchain].address,
    )
  }
  return contract[token][blockchain]
}

export async function mint (
  {
    wallet = WEB3_PROVIDER_METAMASK,
    blockchain = BLOCKCHAIN_BINANCE,
    token = TOKEN_TIME,
    from,
    fromChainId,
    lockId,
    amount,
    signatures,
  },
) {
  const contract = await getContractAsync({ blockchain, token })
  const encodedAbi = contract.methods.mint(fromChainId, lockId, amount, signatures).encodeABI()
  const eventEmitter = await signAndSend({
    wallet,
    from,
    to: contract.options.address,
    encodedAbi,
    blockchain
  })
  return waitTxFromMetamask(eventEmitter)
}

export async function burn (
  {
    wallet = WEB3_PROVIDER_METAMASK,
    blockchain = BLOCKCHAIN_BINANCE,
    token = TOKEN_TIME,
    from,
    toChainId,
    amount,
  },
) {
  const contract = await getContractAsync({ blockchain, token })
  const encodedAbi = contract.methods.burn(toChainId, amount).encodeABI()
  const eventEmitter = await signAndSend({
    wallet,
    from,
    to: contract.options.address,
    encodedAbi,
    blockchain
  })
  return waitTxFromMetamask(eventEmitter)
}

export async function totalSupply (blockchain = BLOCKCHAIN_BINANCE) {
  const contract = await getContractAsync({ blockchain })
  const value = await contract.methods.totalSupply().call()
  return value
}
