<div class="contracts">
  <div class="container">
    <div class="section-header">
      <h3>Contracts</h3>
      <div class="description">TIME Contract Address on:</div>
    </div>
    <div class="addresses">
      <div class="address">
        <div class="name">TIME on Ethereum</div>
        <div class="label">Total supply</div>
        <div class="value">710,113 TIME</div>
        <a class="link" :href="ethTokenLink" target="_blank">
          <tw-icon icon="link" />
          {{ ethTokenLinkText }}
        </a>
      </div>
      <div class="address">
        <div class="name">Bridge Contract</div>
        <div class="label">Total locked</div>
        <div class="value">{{ethTotalLocked}} TIME</div>
        <a class="link" :href="ethBridgeLink" target="_blank">
          <tw-icon icon="link" />
          {{ ethBridgeText }}
        </a>
      </div>
      <div class="address">
        <div class="name">TIME on BNB Chain</div>
        <div class="label">Total minted</div>
        <div class="value">{{ bscTotalMinted }} TIME</div>
        <a class="link" :href="bscTokenLink" target="_blank">
          <tw-icon icon="link" />
          {{ bscTokenLinkText }}
        </a>
      </div>
      <div class="address">
        <div class="name">TIME on Polygon</div>
        <div class="label">Total minted</div>
        <div class="value">{{ polygonTotalMinted }} TIME</div>
        <a class="link" :href="polygonTokenLink" target="_blank">
          <tw-icon icon="link" />
          {{ polygonTokenLinkText }}
        </a>
      </div>
    </div>
  </div>
</div>
