<bg-modal class="root tx-status-modal" @close="close">
  <div class="content">
    <template v-if="isBadNetwork">
      <template v-if="isMetamask">
        <div class="image">
          <img src="/static/images/modal/error.svg" alt="Success" />
        </div>
        <div class="title error">Wrong Network</div>
        <div class="text">Choose {{ requiredNetworkName }} network in Metamask</div>
      </template>
      <template v-if="isWalletConnect || connectingWalletConnect">
        <div class="image">
          <img src="/static/images/modal/error.svg" alt="Success" />
        </div>
        <div class="title error">Wrong Network</div>
        <div class="text">
          To connect to the required {{ requiredNetworkName }} network in WalletConnect App, you need to reconnect with the desired network by clicking the button below.
          However, some wallets are not able to automatically connect to the desired network, for example Metamask, and then you yourself will have to manually add and select a network in this wallet
        </div>
        <div class="action">
          <button @click="clickReconnect" class="btn-reconnect" :class="{ 'btn-reconnecting': connectingWalletConnect }">
            <bg-loader class="loader" v-if="connectingWalletConnect" />
            <span v-else>Reconnect</span>
          </button>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="image">
        <img src="/static/images/modal/success.svg" alt="Success" />
      </div>
      <div class="title success">Network is correct</div>
      <div class="text">Click the button below to continue</div>
      <div class="action">
        <button @click="clickContinue" class="btn-claim">
          CONTINUE
        </button>
      </div>
    </template>
  </div>
</bg-modal>
