import { BACKEND_BASE, BACKEND_RATES_BASE } from '@/api/base'

export async function getTransactions ({ sender, txIdLocked = null, txIdBurned = null, txIdUnlocked = null, txIdMinted = null }) {
  return BACKEND_BASE.get('/transactions/search', {
    params: {
      sender,
      txidLocked: txIdLocked,
      txidBurned: txIdBurned,
      txidUnlocked: txIdUnlocked,
      txidMinted: txIdMinted,
    },
  })
}

export async function getRates () {
  return BACKEND_RATES_BASE.get('rates/range24h', {
    params: { currency: 'TIME' }
  })
}
