import { mapState } from 'vuex'
import { getCurrency } from '@/utils/currency'
import { getArtifactsAsync } from '@/servicies/contracts/Locker'
import { format } from '@/utils/moneyFormat'
import { BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_BINANCE, BLOCKCHAIN_POLYGON } from '@/constants/blockchain'

export default {
  computed: {
    ...mapState({
      contractBalances: (state) => state.network.contractBalances,
    }),
    ethTotalLocked () {
      const currency = getCurrency({ name: 'TIME' })
      return format(
        this.timeLockerAddress ? this.contractBalances?.[BLOCKCHAIN_ETHEREUM]?.[this.timeLockerAddress] : 0,
        {
          divider: currency?.baseUnits,
          toFixedNumber: currency?.digitsAfterDecimalShow,
        },
      )
    },
    bscTotalMinted () {
      const currency = getCurrency({
        name: 'TIME',
        blockchain: BLOCKCHAIN_BINANCE,
      })
      return format(
        this.contractBalances?.[BLOCKCHAIN_BINANCE]?.[currency.address] || 0,
        {
          divider: currency?.baseUnits,
          toFixedNumber: currency?.digitsAfterDecimalShow,
        },
      )
    },
    polygonTotalMinted () {
      const currency = getCurrency({
        name: 'TIME',
        blockchain: BLOCKCHAIN_POLYGON,
      })
      return format(
        this.contractBalances?.[BLOCKCHAIN_POLYGON]?.[currency.address] || 0,
        {
          divider: currency?.baseUnits,
          toFixedNumber: currency?.digitsAfterDecimalShow,
        },
      )
    },
    ethTokenAddress () {
      const currency = getCurrency({
        blockchain: BLOCKCHAIN_ETHEREUM,
        name: 'TIME',
      })
      return currency.address
    },
    ethTokenLink () {
      return `${process.env.VUE_APP_EXPLORER_ETHEREUM}/token/${this.ethTokenAddress}`
    },
    ethTokenLinkText () {
      return `etherscan.io/token/${this.ethTokenAddress.substr(0, 6)}...${this.ethTokenAddress.substr(-4)}`
    },
    ethBridgeAddress () {
      return process.env.VUE_APP_BRIDGE_ETHEREUM
    },
    ethBridgeLink () {
      return `${process.env.VUE_APP_EXPLORER_ETHEREUM}/address/${this.ethBridgeAddress}`
    },
    ethBridgeText () {
      return `etherscan.io/address/${this.ethBridgeAddress.substr(0, 6)}...${this.ethBridgeAddress.substr(-4)}`
    },
    bscTokenAddress () {
      const currency = getCurrency({
        blockchain: BLOCKCHAIN_BINANCE,
        name: 'TIME',
      })
      return currency.address
    },
    polygonTokenAddress () {
      const currency = getCurrency({
        blockchain: BLOCKCHAIN_POLYGON,
        name: 'TIME',
      })
      return currency.address
    },
    bscTokenLink () {
      return `${process.env.VUE_APP_EXPLORER_BINANCE}/token/${this.bscTokenAddress}`
    },
    polygonTokenLink () {
      return `${process.env.VUE_APP_EXPLORER_POLYGON}/token/${this.polygonTokenAddress}`
    },
    bscTokenLinkText () {
      return `bscscan.com/token/${this.bscTokenAddress.substr(0, 6)}...${this.bscTokenAddress.substr(-4)}`
    },
    polygonTokenLinkText () {
      return `polygonscan.com/token/${this.polygonTokenAddress.substr(0, 6)}...${this.polygonTokenAddress.substr(-4)}`
    },
  },
  data () {
    return {
      timeLockerAddress: null,
    }
  },
  async created () {
    const artifacts = await getArtifactsAsync()
    this.timeLockerAddress = artifacts.networks[BLOCKCHAIN_ETHEREUM].address
  },
}
