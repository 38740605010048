import {
  eventEmitter as eventEmitterMetamask,
  isMetamaskInstalled,
  getAddress as getAddressMetamask,
  getChainId as getChainIdMetamask,
} from '@/servicies/metamask'
import {
  eventEmitter as eventEmitterWalletConnect,
  getProviderAsync,
  getChainsAndAddress as getChainsAndAddressWalletConnect,
  sessionConnect,
  getSession,
  resetSession,
  resetProvider
} from '@/servicies/walletConnect'
import {
  WEB3_PROVIDER_METAMASK,
  BLOCKCHAIN_BINANCE,
  BLOCKCHAIN_ETHEREUM,
  BLOCKCHAIN_POLYGON,
  ALLOWED_NETWORKS,
  WEB3_PROVIDER_WALLETCONNECT
} from '@/constants/blockchain'
import { CURRENCIES } from '@/constants/currencies'
import { getBalance } from '@/servicies/balance'
import { getTransactions } from '@/api/backend'
import BackendTransaction from '@/models/BackendTransaction'
import { getArtifactsAsync } from '@/servicies/contracts/Locker'
import { getCurrency } from '@/utils/currency'
import WalletconnectQr from '@/modals/WalletconnectQr/WalletconnectQr.vue'
import { totalSupply } from '@/servicies/contracts/DerivativeToken'
import store from 'store'

const zeroBalancesByBlockchain = () => {
  return {
    [BLOCKCHAIN_ETHEREUM]: {},
    [BLOCKCHAIN_BINANCE]: {},
  }
}

const getInitialState = () => ({
  userSelectedWallet: null,
  walletActive: false,
  walletConnected: false,
  walletConnectUri: null,
  walletConnectModalId: null,
  walletAddress: null,
  walletChainIds: null,
  intervalBalances: null,
  transactions: null,
  txUpdateInterval: {},
  loadTransactionsInterval: null,
  balances: zeroBalancesByBlockchain(),
  contractBalances: zeroBalancesByBlockchain(),
})

const compareFn = (a, b) => {
  if (a.id < b.id) {
    return 1
  }
  if (a.id > b.id) {
    return -1
  }
  return 0
}

export default () => {
  return ({
    namespaced: true,
    state: getInitialState(),
    getters: {
      isActiveWallet (state) {
        return state.walletActive && state.walletConnected
      },
      isWrongNetwork (state) {
        return !ALLOWED_NETWORKS.some(chain =>
          state?.walletChainIds?.includes(chain)
        )
      },
      pendingTransactions (state) {
        return (state.transactions || []).filter(x => x.isPending || x.loading)
      },
      historyTransactions (state) {
        return (state.transactions || []).filter(x => x.isFinish && !x.loading)
      },
      walletConnectModalId (state) {
        return state.walletConnectModalId
      },
      walletConnectError (state) {
        return state.walletConnectError
      },
    },
    mutations: {
      setUserSelectedWallet: (state, wallet) => {
        state.userSelectedWallet = wallet
      },
      setWalletActive: (state, flag) => {
        state.walletActive = flag
      },
      setWalletConnected: (state, flag) => {
        state.walletConnected = flag
      },
      setWalletAddress: (state, address) => {
        state.walletAddress = address
      },
      setWalletChainIds: (state, chainIds) => {
        state.walletChainIds = chainIds
      },
      setIntervalBalances: (state, interval) => {
        state.setIntervalBalances = interval
      },
      setZeroBalances: (state) => {
        state.balances = zeroBalancesByBlockchain()
      },
      setBalance: (state, {
        blockchain,
        erc20Address,
        balance,
      }) => {
        state.balances = {
          ...state.balances,
          [blockchain]: {
            ...state.balances[blockchain],
            [erc20Address]: balance,
          },
        }
      },
      setContractBalance: (state, {
        blockchain,
        address,
        balance,
      }) => {
        state.contractBalances = {
          ...state.contractBalances,
          [blockchain]: {
            ...state.contractBalances[blockchain],
            [address]: balance,
          },
        }
      },
      setTransactionsLoading: (state) => {
        state.transactions = null
      },
      setTransactions: (state, transactions) => {
        transactions.sort(compareFn)
        state.transactions = [...(state.transactions || []).filter((x) => x.loading), ...transactions]
      },
      setTransaction: (state, {
        txId,
        transaction,
      }) => {
        const transactions = [
          transaction,
          ...(state.transactions || [])
            .filter((item) => ![item.txid_lock, item.txid_mint, item.txid_burn, item.txid_unlock].includes(txId)),
        ]
        transactions.sort(compareFn)
        state.transactions = transactions
      },
      removeTransaction: (state, { txId }) => {
        state.transactions = state.transactions
          .filter((item) => ![item.txid_lock, item.txid_mint, item.txid_burn, item.txid_unlock].includes(txId))
      },
      setTxUpdateInterval: (state, {
        interval,
        tx,
      }) => {
        if (state.txUpdateInterval[tx]) clearInterval(state.txUpdateInterval[tx])
        state.txUpdateInterval = {
          ...state.txUpdateInterval,
          [tx]: interval,
        }
      },
      setLoadTransactionsInterval: (state, interval) => {
        if (state.loadTransactionsInterval) clearInterval(state.loadTransactionsInterval)
        state.loadTransactionsInterval = interval
      },
      setWalletConnectUri: (state, url) => {
        state.walletConnectUri = url
      },
      setWalletConnectModalId: (state, url) => {
        state.walletConnectModalId = url
      },
    },
    actions: {
      loadContractBalances: async ({ commit }) => {
        const artifactsTimeLocker = await getArtifactsAsync()
        const timeLockerAddress = artifactsTimeLocker.networks[BLOCKCHAIN_ETHEREUM].address
        const timeEth = await getCurrency({
          name: 'TIME',
          blockchain: BLOCKCHAIN_ETHEREUM,
        })
        const balanceTimeLocker = await getBalance({
          blockchain: BLOCKCHAIN_ETHEREUM,
          contractAddress: timeEth.address,
          address: timeLockerAddress,
        })
        commit('setContractBalance', {
          blockchain: BLOCKCHAIN_ETHEREUM,
          address: timeLockerAddress,
          balance: balanceTimeLocker,
        })

        const timeBsc = await getCurrency({
          name: 'TIME',
          blockchain: BLOCKCHAIN_BINANCE,
        })
        const totalSupplyTimeBsc = await totalSupply(BLOCKCHAIN_BINANCE)
        commit('setContractBalance', {
          blockchain: BLOCKCHAIN_BINANCE,
          address: timeBsc.address,
          balance: totalSupplyTimeBsc,
        })

        const timePolygon = await getCurrency({
          name: 'TIME',
          blockchain: BLOCKCHAIN_POLYGON,
        })
        const totalSupplyTimePolygon = await totalSupply(BLOCKCHAIN_POLYGON)
        commit('setContractBalance', {
          blockchain: BLOCKCHAIN_POLYGON,
          address: timePolygon.address,
          balance: totalSupplyTimePolygon,
        })
      },
      loadBalances: async ({
        state,
        commit,
        getters,
        dispatch,
      }, reset = false) => {
        if (getters.isActiveWallet) {
          if (reset && state.intervalBalances) {
            clearInterval(state.intervalBalances)
            commit('setIntervalBalances', setInterval(() => dispatch('loadBalances'), +process.env.VUE_APP_BALANCES_UPDATE_INTERVAL))
          }
          const balances = await Promise.all(CURRENCIES.map(currency => getBalance({
            blockchain: currency.blockchain,
            contractAddress: currency.address,
            address: state.walletAddress,
          })))
          CURRENCIES.forEach((currency, index) => {
            commit('setBalance', {
              blockchain: currency.blockchain,
              erc20Address: currency.address,
              balance: balances[index],
            })
          })
        }
      },
      loadTransactions: async ({
        state,
        commit,
        dispatch,
      }, withLoading = true) => {
        if (withLoading) {
          commit('setTransactionsLoading')
        }
        if (state.loadTransactionsInterval) {
          clearInterval(state.loadTransactionsInterval)
        }
        const fnInterval = async () => {
          if (state.walletActive && state.walletConnected && state.walletAddress) {
            const res = await getTransactions({ sender: state.walletAddress })
            const transactions = (res?.data?.result || []).map((item) => BackendTransaction.fromServer(item))
            commit('setTransactions', transactions)
            for (const transaction of transactions) {
              if (!transaction.confirmed && transaction.isPending) {
                dispatch('updateTransaction', {
                  txIdLocked: transaction.txid_lock,
                  txIdBurned: transaction.txid_burn,
                })
              }
            }
          }
        }
        await fnInterval()
        const interval = setInterval(fnInterval, +process.env.VUE_APP_TRANSACTIONS_UPDATE_INTERVAL)
        commit('setLoadTransactionsInterval', interval)
      },
      updateTransaction: async (
        {
          state,
          commit,
          dispatch,
        }, {
          txIdLocked = null,
          txIdBurned = null,
          txIdUnlocked = null,
          txIdMinted = null,
        },
      ) => {
        const txId = txIdMinted || txIdUnlocked || txIdLocked || txIdBurned
        if (state.txUpdateInterval[txId]) return
        const fnInterval = async () => {
          if (state.walletActive && state.walletConnected && state.walletAddress) {
            const res = await getTransactions({
              sender: state.walletAddress,
              txIdLocked,
              txIdBurned,
              txIdUnlocked,
              txIdMinted,
            })
            const transactions = (res?.data?.result || []).map((item) => BackendTransaction.fromServer(item))
            if (transactions.length > 0) {
              const transaction = transactions[0]
              if (transaction.confirmed) {
                commit('setTxUpdateInterval', {
                  tx: txId,
                  interval: null,
                })
                commit('setTransaction', {
                  txId,
                  transaction,
                })
                return
              }
              if (transaction.isFinish) {
                commit('setTxUpdateInterval', {
                  tx: txId,
                  interval: null,
                })
                dispatch('loadTransactions', false)
              } else {
                commit('setTransaction', {
                  txId,
                  transaction,
                })
              }
            }
          }
        }
        await fnInterval()
        const interval = setInterval(fnInterval, +process.env.VUE_APP_TRANSACTION_UPDATE_INTERVAL)
        commit('setTxUpdateInterval', {
          interval,
          tx: txId,
        })
      },
      init: async ({
        state,
        commit,
        dispatch,
      }) => {
        await dispatch('loadContractBalances')
        if (state.userSelectedWallet === WEB3_PROVIDER_WALLETCONNECT) {
          if (state.walletConnected && state.userSelectedWallet) {
            await dispatch('connect', { wallet: state.userSelectedWallet })
          } else {
            commit('setUserSelectedWallet', null)
            commit('setWalletConnected', false)
          }
        }
        const active = isMetamaskInstalled()
        if (state.walletConnected && state.userSelectedWallet === WEB3_PROVIDER_METAMASK && active) {
          commit('setWalletActive', active)
          if (active) {
            try {
              await dispatch('connectToMetamask')
              const address = await getAddressMetamask()
              const chainId = await getChainIdMetamask()
              if (address && chainId) {
                await dispatch('loadBalances')
                await dispatch('loadTransactions')
                commit('setIntervalBalances', setInterval(() => dispatch('loadBalances'), 30000))
              }
            } catch (err) {
              console.error(err)
            }
          }
        }
      },
      connect: async (
        {
          state,
          commit,
          dispatch,
        }, {
          networks,
          wallet,
        },
      ) => {
        commit('setUserSelectedWallet', wallet)
        if (wallet === WEB3_PROVIDER_METAMASK) {
          const active = isMetamaskInstalled()
          if (active) {
            await dispatch('connectToMetamask')
            const address = await getAddressMetamask()
            const chainId = await getChainIdMetamask()
            if (address && chainId) {
              commit('setWalletConnected', true)
              commit('setWalletAddress', address)
              commit('setWalletChainIds', [chainId])
              await dispatch('loadBalances', true)
              await dispatch('loadTransactions')
            }
          }
        }
        if (wallet === WEB3_PROVIDER_WALLETCONNECT) {
          await dispatch('connectToWalletConnect', networks)
          const { address, listChainIds } = await getChainsAndAddressWalletConnect()
          if (address && listChainIds) {
            await dispatch('loadBalances', true)
            await dispatch('loadTransactions')
          }
        }
      },
      connectToMetamask: async ({
        state,
        commit,
        dispatch,
      }) => {
        const addressListener = async (addresses) => {
          commit('setWalletAddress', addresses[0])
          await commit('setZeroBalances')
          await commit('setTransactions', [])
          await dispatch('loadBalances', true)
          await dispatch('loadTransactions')
        }
        const chainIdListener = async (chainId) => {
          commit('setWalletChainIds', [chainId])
          await commit('setZeroBalances')
          await dispatch('loadBalances', true)
        }
        const connectListener = async () => {
          commit('setWalletConnected', true)
          await commit('setZeroBalances')
          await commit('setTransactions', [])
          await dispatch('loadBalances', true)
          await dispatch('loadTransactions')
        }
        const disconnectListener = async () => {
          await commit('setLoadTransactionsInterval', null)
          commit('setWalletConnected', false)
          await commit('setZeroBalances')
        }
        eventEmitterMetamask.on('accountsChanged', addressListener)
        eventEmitterMetamask.on('chainChanged', chainIdListener)
        eventEmitterMetamask.on('connect', connectListener)
        eventEmitterMetamask.on('disconnect', disconnectListener)
        eventEmitterMetamask.on('start', connectListener)
        eventEmitterMetamask.on('stop', disconnectListener)
        const address = await getAddressMetamask()
        const chainId = await getChainIdMetamask()
        if (address && chainId) {
          commit('setWalletActive', true)
          commit('setWalletConnected', true)
          commit('setWalletAddress', address)
          commit('setWalletChainIds', [chainId])
        }
      },
      connectToWalletConnect: async ({
        state,
        commit,
        dispatch,
      }, chainIds) => {
        const provider = chainIds ? await getProviderAsync(true) : await getProviderAsync()
        try {
          const walletUriListener = async (uri) => {
            await commit('setWalletConnectUri', uri)
            await dispatch('showQrCodeWalletconnect')
          }
          const addressListener = async (addresses) => {
            await commit('setWalletAddress', addresses[0])
            await commit('setTransactions', [])
            await commit('setZeroBalances')
            await dispatch('loadBalances', true)
            await dispatch('loadTransactions')
          }
          const updateChainsAndAddress = async ({ chainIds, addresses }) => {
            await commit('setWalletChainIds', chainIds)
            await commit('setWalletAddress', addresses[0])
            await commit('setTransactions', [])
            await commit('setZeroBalances')
            await dispatch('loadBalances', true)
            await dispatch('loadTransactions')
          }
          const connectListener = async () => {
            await dispatch('ui/closeModal', state.walletConnectModalId, { root: true })
            await commit('setWalletConnected', true)
            await commit('setTransactions', [])
            await commit('setZeroBalances')
            await dispatch('loadBalances', true)
            await dispatch('loadTransactions')
          }
          const disconnectListener = async () => {
            await dispatch('ui/closeModal', state.walletConnectModalId, { root: true })
            await commit('setWalletConnected', false)
            await dispatch('disconnectWallet')
          }
          const setWalletConnectError = async (text) => {
            await dispatch('ui/updateModalParams',
              {
                id: state.walletConnectModalId,
                payload: { props: { error: text } }
              },
              { root: true }
            )
          }
          eventEmitterWalletConnect.on('walletUri', walletUriListener)
          eventEmitterWalletConnect.on('accountsChanged', addressListener)
          eventEmitterWalletConnect.on('updateItems', updateChainsAndAddress)
          eventEmitterWalletConnect.on('connect', connectListener)
          eventEmitterWalletConnect.on('disconnect', disconnectListener)
          eventEmitterWalletConnect.on('start', connectListener)
          eventEmitterWalletConnect.on('stop', disconnectListener)
          eventEmitterWalletConnect.on('setWalletConnectError', setWalletConnectError)
          if (provider && !getSession()) {
            await sessionConnect(chainIds)
            const accounts = await provider.enable()
            eventEmitterWalletConnect.emit('accountsChanged', accounts)
          }
          const { address, listChainIds } = await getChainsAndAddressWalletConnect()
          if (address && listChainIds) {
            commit('setWalletActive', true)
            commit('setWalletConnected', true)
            commit('setWalletAddress', address)
            commit('setWalletChainIds', listChainIds)
          }
        } catch (err) {
          if (provider && state.walletConnected) {
            await provider.disconnect()
          }
          console.error(err)
          eventEmitterWalletConnect.emit('setWalletConnectError', err?.message ?? err)
        }
      },
      disconnectWallet: async ({
        state,
        commit,
        dispatch,
      }) => {
        try {
          if (state.userSelectedWallet === WEB3_PROVIDER_WALLETCONNECT) {
            const provider = await getProviderAsync()
            if (state.walletConnected) {
              await provider.disconnect()
            }
            await resetSession({ hardReset: true })
            await resetProvider()
            store.each((value, key) => {
              if (key && key?.slice(0, 4) === 'wc@2') {
                store.remove(key)
              }
            })
          }
        } catch (err) {
          console.error('Error disconnectWallet', err)
        }
        commit('setWalletConnected', false)
        commit('setUserSelectedWallet', null)
        commit('setWalletAddress', null)
        commit('setWalletChainIds', [])
        commit('setZeroBalances')
        commit('setTransactions', [])
      },
      showQrCodeWalletconnect: async ({
        state,
        commit,
        dispatch
      }) => {
        if (state.walletConnectUri) {
          const modalId = await dispatch('ui/openModal', {
            component: () => WalletconnectQr,
            props: {
              uri: state.walletConnectUri,
              error: state.walletConnectError
            }
          }, { root: true })
          await commit('setWalletConnectModalId', modalId)
        }
      },
    },
  })
}
