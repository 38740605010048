import { mapState } from 'vuex'
import BigNumber from 'bignumber.js'
import { format } from '@/utils/moneyFormat'

export default {
  data () {
    return {
      activeProject: 0,
      projects: [
        {
          name: 'LaborX',
          url: 'https://laborx.com',
          text: 'Blockchain-based freelance jobs platform with digital work agreements and cryptocurrency payments.',
          logo: '/static/images/icon/lx.svg',
        },
        {
          name: 'TimeX',
          url: 'https://timex.io',
          // eslint-disable-next-line max-len
          text: 'Plasma-based hybrid cryptocurrency exchange, combining the speed and privacy of a centralised system with the security and transparency of a decentralised solution',
          logo: '/static/images/icon/timex.svg',
        },
        {
          name: 'PaymentX',
          url: 'https://paymentx.io',
          text: 'Cryptocurrency payroll solution to automate businesses’ crypto payments for employees and contractors',
          logo: '/static/images/icon/px.svg',
        },
        {
          name: 'AUDT',
          url: 'https://audt.to',
          text: 'Compliant stablecoin backed by AUD reserves',
          logo: '/static/images/icon/audt.svg',
        }
      ],
    }
  },
  computed: {
    ...mapState({
      timePrice: state => state.timePrice
    }),
    timePriceFormatted () {
      return format(this.timePrice, { toFixedNumber: 2 })
    },
    marketCapFormatted () {
      return format(new BigNumber(this.timePrice || 0).multipliedBy(710113), { toFixedNumber: 2 })
    },
  },
  methods: {
    urlFormatted (url) {
      return url.substr(8)
    },
  },
}
