import { getWeb3Async } from '@/servicies/web3'
import {
  BLOCKCHAIN_BINANCE,
  BLOCKCHAIN_ETHEREUM,
  BLOCKCHAIN_POLYGON,
  WEB3_PROVIDER_METAMASK,
} from '@/constants/blockchain'
import { waitTxFromMetamask } from '@/utils/blockchain'
import { signAndSend } from '@/servicies/transaction'

const contractsMap = {
  [BLOCKCHAIN_BINANCE]: {},
  [BLOCKCHAIN_ETHEREUM]: {},
  [BLOCKCHAIN_POLYGON]: {},
}

export async function getContractAsync ({ blockchain, contractAddress }) {
  const web3 = await getWeb3Async({ blockchain })
  const artifacts = await import(/* webpackChunkName: 'timewarp-sc-artifacts' */ 'timebridge-sc-artifacts/artifacts/ETHTimeToken.json')
  if (!contractsMap[blockchain][contractAddress]) {
    contractsMap[blockchain][contractAddress] = new web3.eth.Contract(artifacts.abi, contractAddress)
  }
  return contractsMap[blockchain][contractAddress]
}

export async function allowance ({ blockchain, contractAddress, owner, spender }) {
  const contract = await getContractAsync({
    blockchain,
    contractAddress,
  })
  const value = await contract.methods.allowance(owner, spender).call()
  return value
}

export async function balanceOf ({ blockchain, contractAddress, who }) {
  const contract = await getContractAsync({
    blockchain,
    contractAddress,
  })
  const value = await contract.methods.balanceOf(who).call()
  return value
}

export async function approve (
  {
    wallet = WEB3_PROVIDER_METAMASK,
    blockchain,
    contractAddress,
    from,
    spender,
    amount,
  },
) {
  const contract = await getContractAsync({
    blockchain,
    contractAddress,
  })
  const encodedAbi = contract.methods.approve(spender, amount).encodeABI()
  const eventEmitter = await signAndSend({
    wallet,
    from,
    to: contractAddress,
    encodedAbi,
  })
  return waitTxFromMetamask(eventEmitter)
}

export async function transferAndCall (
  {
    wallet = WEB3_PROVIDER_METAMASK,
    blockchain,
    contractAddress,
    from,
    to,
    amount,
    data,
  },
) {
  const contract = await getContractAsync({
    blockchain,
    contractAddress,
  })
  const encodedAbi = contract.methods.transferAndCall(to, amount, data).encodeABI()
  const eventEmitter = await signAndSend({
    wallet,
    from,
    to: contractAddress,
    encodedAbi,
  })
  return waitTxFromMetamask(eventEmitter)
}
