<div class="pending">
  <div class="container">
    <fieldset>
      <legend>Transactions History</legend>
      <div class="transactions">
        <div class="row labels">
          <div class="tokens">Tokens</div>
          <div class="from">From</div>
          <div class="to">To</div>
          <div class="tx">TXs</div>
        </div>
        <div v-for="transaction in historyTransactions" :key="transaction.id" class="row transaction">
          <div class="tokens"><span class="name">Amount: </span>{{ formatTime(transaction) }} {{transaction.currencyFrom.name}}</div>
          <div class="from">
            <span class="name">From: </span>
            <div class="network">
              <img :src="getNetworkIcon(transaction.fromChainId)" :alt="getNetworkName(transaction.from)" />
              <span>{{ getNetworkName(transaction.fromChainId) }}</span>
            </div>
          </div>
          <div class="to">
            <span class="name">To: </span>
            <div class="network">
              <img :src="getNetworkIcon(transaction.toChainId)" :alt="getNetworkName(transaction.to)" />
              <span>{{ getNetworkName(transaction.toChainId) }}</span>
            </div>
          </div>
          <div class="tx">
            <a :href="getLink(transaction.fromChainId, transaction.fromTxId)" target="_blank">{{ formatTxId(transaction.fromTxId) }}</a>
            <a :href="getLink(transaction.toChainId, transaction.toTxId)" target="_blank">{{ formatTxId(transaction.toTxId) }}</a>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</div>
