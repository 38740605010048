<div class="page">
  <header-block />
  <swap />
  <pending />
  <history v-if="historyTransactions && historyTransactions.length > 0" />
  <contracts />
  <info />
  <footer-block />
<!--  <div v-else class="m-mode">-->
<!--    <div class="h">Maintenance Mode</div>-->
<!--    <div class="t">The service is in maintenance mode and will be available after the ethereum merge</div>-->
<!--  </div>-->
</div>
