<bg-modal class="root tx-status-modal">
  <div class="content">
    <div class="image">
      <img v-if="type === 'success'" src="/static/images/modal/success.svg" alt="Success" />
      <img v-else-if="type === 'error'" src="/static/images/modal/error.svg" alt="Success" />
      <bg-loader v-else class="loading" width="2" key="loading" />
    </div>
    <div class="title" :class="type">{{ title || defaultTitle }}</div>
    <div class="text" v-if="text" v-html="text"></div>
  </div>
</bg-modal>
