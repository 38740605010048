import Deferred from 'promise-deferred'
import { BLOCKCHAIN_BINANCE, BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_POLYGON, EXPLORER_BY_BLOCKCHAIN } from '@/constants/blockchain'

export function getExplorerLink ({ blockchain = BLOCKCHAIN_ETHEREUM, tx }) {
  return `${EXPLORER_BY_BLOCKCHAIN[blockchain]}/tx/${tx}`
}

export function waitTxFromMetamask (eventEmitter) {
  const deferred = new Deferred()
  eventEmitter
    .on('transactionHash', (hash) => {
      deferred.resolve(hash)
    })
    .on('error', (err) => {
      console.error(err)
      deferred.reject(err)
    })
  return deferred.promise
}

export function getConfirmationsCountByBlockchain (blockchain) {
  switch (blockchain) {
    case BLOCKCHAIN_ETHEREUM: return process.env.VUE_APP_ETH_CONFIRMATIONS_VALUE
    case BLOCKCHAIN_BINANCE: return process.env.VUE_APP_BSC_CONFIRMATIONS_VALUE
    case BLOCKCHAIN_POLYGON: return process.env.VUE_APP_POLYGON_CONFIRMATIONS_VALUE
  }
  return 15
}
