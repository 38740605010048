import EventEmitter from 'events'
import Deferred from 'promise-deferred'
import { getWeb3Async } from '@/servicies/web3'
import { hasTransactionEvent } from '@/utils/transaction'
import {
  DEFAULT_GAS_MULTIPLIED_BY_BLOCKCHAIN,
  WEB3_PROVIDER_METAMASK,
  WEB3_PROVIDER_WALLETCONNECT
} from '@/constants/blockchain'
import BigNumber from 'bignumber.js'

export async function signAndSend ({
  wallet,
  from,
  to,
  encodedAbi,
  blockchain
}) {
  const eventEmitter = new EventEmitter()
  const web3 = await getWeb3Async({ walletGroup: wallet, walletBlockchain: blockchain, newInitWallet: true })
  let responseEmitter = null
  let gasPrice = await web3.eth.getGasPrice()
  if (blockchain) {
    gasPrice = (new BigNumber(gasPrice).multipliedBy(DEFAULT_GAS_MULTIPLIED_BY_BLOCKCHAIN[blockchain])).toFixed(0)
  }
  if (wallet === WEB3_PROVIDER_METAMASK) {
    responseEmitter = web3.eth.sendTransaction({
      from,
      to,
      value: '0',
      data: encodedAbi,
      gasPrice
    })
  }
  if (wallet === WEB3_PROVIDER_WALLETCONNECT) {
    responseEmitter = web3.eth.sendTransaction({
      from,
      to,
      value: '0',
      data: encodedAbi,
      chainId: web3.utils.toHex(blockchain),
      gasPrice
    })
  }
  responseEmitter.on('transactionHash', (hash) => {
    setImmediate(() => eventEmitter.emit('transactionHash', hash))
  })
  responseEmitter.on('error', (error) => {
    setImmediate(() => eventEmitter.emit('error', error))
  })
  return eventEmitter
}

export async function awaitTransactionStatusAppliedBlockchain ({ blockchain, tx, eventSignature = null, eventAddress = null }) {
  const deferred = new Deferred()
  const interv = setInterval(async () => {
    const web3 = await getWeb3Async({ blockchain })
    const receipt = await web3.eth.getTransactionReceipt(tx)
    // console.log('receipt', receipt)
    if (receipt?.status === false) {
      deferred.reject('receipt.status === false')
      clearInterval(interv)
    }
    if (receipt && receipt.blockNumber) {
      const blockNumber = await web3.eth.getBlockNumber()
      if (blockNumber - receipt.blockNumber >= 1) {
        if (eventSignature !== null) {
          if (hasTransactionEvent({ receipt, signature: eventSignature, eventAddress })) {
            deferred.resolve()
            clearInterval(interv)
          } else {
            deferred.reject('Not has transaction event')
            clearInterval(interv)
          }
        } else {
          deferred.resolve()
          clearInterval(interv)
        }
      }
    }
  }, 1000)
  return deferred.promise
}
