<bg-modal class="root account-modal">
  <h3 class="modal-title">Account Details</h3>
  <div class="content">
    <div class="wallet-type">
      <span>
        <template v-if="metamaskSelected">
        Connected with MetaMask
        </template>
        <template v-if="walletConnectSelected">
        Connected with WalletConnect
        </template>
      </span>
      <div class="disconnect" @click="disconnect">
        Disconnect
      </div>
    </div>
    <div class="wallet">
      <img v-if="isEthNetwork" class="network-icon" src="/static/images/icon/eth.svg" alt="Wallet" />
      <img v-if="isBscNetwork" class="network-icon" src="/static/images/icon/bsc.svg" alt="Wallet" />
      <img v-if="isPolygonNetwork" class="network-icon" src="/static/images/icon/polygon.svg" alt="Wallet" />
      <img
          v-if="isEthNetwork && !isBscNetwork && !isPolygonNetwork"
          class="network-icon"
          src="/static/images/header/account-1x.png"
          srcset="/static/images/header/account-2x.png 2x"
          alt="Wallet"
      />
      <span>{{ walletFormatted }}</span>
    </div>
    <div class="buttons">
      <button class="btn" @click="onCopyClick">
        <span>Copy address</span>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M4.66667 3.99998V1.99998C4.66667 1.82317 4.7369 1.6536 4.86193 1.52858C4.98695 1.40355 5.15652 1.33331 5.33333 1.33331H13.3333C13.5101 1.33331 13.6797 1.40355 13.8047 1.52858C13.9298 1.6536 14 1.82317 14 1.99998V11.3333C14 11.5101 13.9298 11.6797 13.8047 11.8047C13.6797 11.9297 13.5101 12 13.3333 12H11.3333V14C11.3333 14.368 11.0333 14.6666 10.662 14.6666H2.67133C2.58342 14.6672 2.49626 14.6503 2.41488 14.6171C2.3335 14.5838 2.25949 14.5348 2.19711 14.4728C2.13472 14.4109 2.0852 14.3372 2.05137 14.2561C2.01754 14.1749 2.00009 14.0879 2 14L2.002 4.66665C2.002 4.29865 2.302 3.99998 2.67333 3.99998H4.66667ZM3.33533 5.33331L3.33333 13.3333H10V5.33331H3.33533ZM6 3.99998H11.3333V10.6666H12.6667V2.66665H6V3.99998Z"
              fill="#4468FF" />
        </svg>
      </button>
      <a v-if="!isWrongNetwork" :href="linkToEtherscan" target="_blank" rel="noopener" class="btn">
        <span>View on Etherscan</span>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M6.66667 4V5.33333H3.33333V12.6667H10.6667V9.33333H12V13.3333C12 13.5101 11.9298 13.6797 11.8047 13.8047C11.6797 13.9298 11.5101 14 11.3333 14H2.66667C2.48986 14 2.32029 13.9298 2.19526 13.8047C2.07024 13.6797 2 13.5101 2 13.3333V4.66667C2 4.48986 2.07024 4.32029 2.19526 4.19526C2.32029 4.07024 2.48986 4 2.66667 4H6.66667ZM14 2V8L11.4707 5.47133L7.47133 9.47133L6.52867 8.52867L10.528 4.52867L8 2H14Z"
              fill="#4468FF" />
        </svg>
      </a>
      <a v-if="!isWrongNetwork" :href="linkToBscscan" target="_blank" rel="noopener" class="btn">
        <span>View on BscScan</span>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M6.66667 4V5.33333H3.33333V12.6667H10.6667V9.33333H12V13.3333C12 13.5101 11.9298 13.6797 11.8047 13.8047C11.6797 13.9298 11.5101 14 11.3333 14H2.66667C2.48986 14 2.32029 13.9298 2.19526 13.8047C2.07024 13.6797 2 13.5101 2 13.3333V4.66667C2 4.48986 2.07024 4.32029 2.19526 4.19526C2.32029 4.07024 2.48986 4 2.66667 4H6.66667ZM14 2V8L11.4707 5.47133L7.47133 9.47133L6.52867 8.52867L10.528 4.52867L8 2H14Z"
              fill="#4468FF" />
        </svg>
      </a>
      <a v-if="!isWrongNetwork" :href="linkToPolygonscan" target="_blank" rel="noopener" class="btn">
        <span>View on Polygonscan</span>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M6.66667 4V5.33333H3.33333V12.6667H10.6667V9.33333H12V13.3333C12 13.5101 11.9298 13.6797 11.8047 13.8047C11.6797 13.9298 11.5101 14 11.3333 14H2.66667C2.48986 14 2.32029 13.9298 2.19526 13.8047C2.07024 13.6797 2 13.5101 2 13.3333V4.66667C2 4.48986 2.07024 4.32029 2.19526 4.19526C2.32029 4.07024 2.48986 4 2.66667 4H6.66667ZM14 2V8L11.4707 5.47133L7.47133 9.47133L6.52867 8.52867L10.528 4.52867L8 2H14Z"
              fill="#4468FF" />
        </svg>
      </a>
    </div>
  </div>
</bg-modal>
