export default {
  props: {
    type: {
      type: String,
      validator: function (value) {
        return ['success', 'error', 'process'].indexOf(value) !== -1
      }
    },
    title: String,
    text: String,
  },
  computed: {
    defaultTitle () {
      switch (this.type) {
        case 'success': return 'Success!'
        case 'error': return 'Error'
        case 'process': return 'Processing...'
      }
    },
  },
}
