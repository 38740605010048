<div class="header">
  <div class="header-container">
    <a href="/" class="logo">
      <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.10714" y="1.60714" width="37.6429" height="28.7857" rx="4.42857" stroke="#6784FF" stroke-width="2.21429"/>
        <circle cx="19.9287" cy="15.9999" r="7.75" stroke="#6784FF" stroke-width="2.21429"/>
        <path d="M22.1427 17.1068H18.8213V13.7854" stroke="#6784FF" stroke-width="2.21429" stroke-linecap="round"/>
      </svg>
      <span>TIME Bridge</span>
    </a>
    <div class="wallet">
      <div class="info" v-if="isActiveWallet">
        <div class="balance">
          <div>{{ balanceFormattedTIME }} TIME</div>
          <div>{{ balanceFormattedCGU }} CGU</div>
        </div>
        <div class="account">
          <div v-if="isWrongNetwork" class="wrong-network" @click="onAccountClick">
            Wrong network
          </div>
          <button v-else class="btn-header btn-account" @click="onAccountClick">
            <img v-if="isEthNetwork" class="network-icon" src="/static/images/icon/eth.svg" alt="Wallet" />
            <img v-if="isBscNetwork" class="network-icon" src="/static/images/icon/bsc.svg" alt="Wallet" />
            <img v-if="isPolygonNetwork" class="network-icon" src="/static/images/icon/polygon.svg" alt="Wallet" />
            <img v-if="!isEthNetwork && !isBscNetwork && !isPolygonNetwork" src="/static/images/header/account-1x.png" srcset="/static/images/header/account-2x.png 2x" alt="Wallet" />
            <span>{{ walletFormatted }}</span>
          </button>

        </div>
      </div>
      <button v-else class="btn-header btn-connect" @click="onConnectClick">
        <img src="/static/images/icon/wallet.svg" alt="Connect wallet" />
        <span>CONNECT WALLET</span>
      </button>
    </div>
  </div>
</div>
