import Joi from 'joi'
import {
  TRANSACTION_TYPE_ETH_TO_BSC,
  TRANSACTION_TYPE_BSC_TO_ETH,
  TRANSACTION_TYPE_POLYGON_TO_ETH,
  TRANSACTION_TYPE_ETH_TO_POLYGON,
  TRANSACTION_TYPE_BSC_TO_POLYGON,
  TRANSACTION_TYPE_POLYGON_TO_BSC,
  TRANSACTION_STATUS_LOCKED,
  TRANSACTION_STATUS_MINTED,
  TRANSACTION_STATUS_BURNED,
  TRANSACTION_STATUS_UNLOCKED,
  EVENT_BURNED,
  EVENT_LOCKED,
  EVENT_MINTED,
  EVENT_UNLOCKED, TOKEN_TIME, TOKEN_CGU
} from '@/constants/backend'
import { BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_BINANCE, BLOCKCHAIN_POLYGON } from '@/constants/blockchain'
import AbstractModel from './AbstractModel'
import { getCurrency } from '@/utils/currency'

class BackendTransaction extends AbstractModel {
  static propTypes = {
    id: Joi.number(),
    sender: Joi.string(),
    token_id: Joi.number().valid(
      TOKEN_TIME,
      TOKEN_CGU
    ).default(TOKEN_TIME),
    type: Joi.number().valid(
      TRANSACTION_TYPE_ETH_TO_BSC,
      TRANSACTION_TYPE_BSC_TO_ETH,
      TRANSACTION_TYPE_ETH_TO_POLYGON,
      TRANSACTION_TYPE_POLYGON_TO_ETH,
      TRANSACTION_TYPE_BSC_TO_POLYGON,
      TRANSACTION_TYPE_POLYGON_TO_BSC
    ),
    status: Joi.number().valid(
      TRANSACTION_STATUS_LOCKED,
      TRANSACTION_STATUS_MINTED,
      TRANSACTION_STATUS_BURNED,
      TRANSACTION_STATUS_UNLOCKED
    ),
    txid_lock: Joi.string().allow(null),
    txid_mint: Joi.string().allow(null),
    txid_burn: Joi.string().allow(null),
    txid_unlock: Joi.string().allow(null),
    loading: Joi.boolean().default(false),
    validators: Joi.array().items(
      Joi.object().keys({
        id: Joi.number(),
        validator: Joi.string(),
        blockchain: Joi.number(),
        event: Joi.string().valid(
          EVENT_LOCKED,
          EVENT_UNLOCKED,
          EVENT_BURNED,
          EVENT_MINTED
        ),
        amount: Joi.string(),
        sender: Joi.string(),
        txid: Joi.string(),
        confirmations: Joi.number(),
        message: Joi.object().keys({
          sig: Joi.object(),
          rawParams: Joi.array(),
          flatSig: Joi.string()
        }).allow(null),
        bridge_transaction_id: Joi.number()
      })
    ).default([])
  }

  static getTransactionType (fromNetwork, toNetwork) {
    if (fromNetwork === BLOCKCHAIN_ETHEREUM && toNetwork === BLOCKCHAIN_BINANCE) return TRANSACTION_TYPE_ETH_TO_BSC
    if (fromNetwork === BLOCKCHAIN_BINANCE && toNetwork === BLOCKCHAIN_ETHEREUM) return TRANSACTION_TYPE_BSC_TO_ETH
    if (fromNetwork === BLOCKCHAIN_ETHEREUM && toNetwork === BLOCKCHAIN_POLYGON) return TRANSACTION_TYPE_ETH_TO_POLYGON
    if (fromNetwork === BLOCKCHAIN_POLYGON && toNetwork === BLOCKCHAIN_ETHEREUM) return TRANSACTION_TYPE_POLYGON_TO_ETH
    if (fromNetwork === BLOCKCHAIN_BINANCE && toNetwork === BLOCKCHAIN_POLYGON) return TRANSACTION_TYPE_BSC_TO_POLYGON
    if (fromNetwork === BLOCKCHAIN_POLYGON && toNetwork === BLOCKCHAIN_BINANCE) return TRANSACTION_TYPE_POLYGON_TO_BSC
  }

  get fromTxId () {
    if (this.txid_lock) {
      return this.txid_lock
    } else if (this.txid_burn) {
      return this.txid_burn
    } else {
      return null
    }
  }

  get toTxId () {
    if (this.txid_mint) {
      return this.txid_mint
    } else if (this.txid_unlock) {
      return this.txid_unlock
    } else {
      return null
    }
  }

  get amount () {
    const validators = (this.validators || [])
    return validators[0].amount
  }

  get confirmations () {
    const validators = (this.validators || [])
    return validators.length === 0 || validators.length === 1
      ? 0
      : Math.min(...validators.map(x => x.confirmations))
  }

  get confirmationRequired () {
    switch (this.type) {
      case TRANSACTION_TYPE_ETH_TO_BSC:
        return +process.env.VUE_APP_ETH_CONFIRMATIONS_VALUE
      case TRANSACTION_TYPE_BSC_TO_ETH:
        return +process.env.VUE_APP_BSC_CONFIRMATIONS_VALUE
      case TRANSACTION_TYPE_ETH_TO_POLYGON:
        return +process.env.VUE_APP_ETH_CONFIRMATIONS_VALUE
      case TRANSACTION_TYPE_POLYGON_TO_ETH:
        return +process.env.VUE_APP_POLYGON_CONFIRMATIONS_VALUE
      case TRANSACTION_TYPE_BSC_TO_POLYGON:
        return +process.env.VUE_APP_BSC_CONFIRMATIONS_VALUE
      case TRANSACTION_TYPE_POLYGON_TO_BSC:
        return +process.env.VUE_APP_POLYGON_CONFIRMATIONS_VALUE
    }
  }

  checkIsNotEmptyObject (obj) {
    return !!obj &&
      typeof obj === 'object' &&
      Object.keys(obj).length > 0
  }

  get confirmed () {
    return this.confirmations >= this.confirmationRequired &&
      this.fromValidators.length > 0 &&
      this.checkIsNotEmptyObject(this.fromValidators[0].message)
  }

  get fromValidators () {
    return this.validators.filter((item) => [EVENT_LOCKED, EVENT_BURNED].includes(item.event))
  }

  get fromChainId () {
    switch (this.type) {
      case TRANSACTION_TYPE_ETH_TO_BSC:
        return BLOCKCHAIN_ETHEREUM
      case TRANSACTION_TYPE_BSC_TO_ETH:
        return BLOCKCHAIN_BINANCE
      case TRANSACTION_TYPE_ETH_TO_POLYGON:
        return BLOCKCHAIN_ETHEREUM
      case TRANSACTION_TYPE_POLYGON_TO_ETH:
        return BLOCKCHAIN_POLYGON
      case TRANSACTION_TYPE_BSC_TO_POLYGON:
        return BLOCKCHAIN_BINANCE
      case TRANSACTION_TYPE_POLYGON_TO_BSC:
        return BLOCKCHAIN_POLYGON
    }
  }

  get currencyFrom () {
    if (this.token_id === TOKEN_CGU) {
      return getCurrency({ name: 'CGU', blockchain: this.fromChainId })
    } else {
      return getCurrency({ name: 'TIME', blockchain: this.fromChainId })
    }
  }

  get currencyTo () {
    if (this.token_id === TOKEN_CGU) {
      return getCurrency({ name: 'CGU', blockchain: this.toChainId })
    } else {
      return getCurrency({ name: 'TIME', blockchain: this.toChainId })
    }
  }

  get toChainId () {
    switch (this.type) {
      case TRANSACTION_TYPE_ETH_TO_BSC:
        return BLOCKCHAIN_BINANCE
      case TRANSACTION_TYPE_BSC_TO_ETH:
        return BLOCKCHAIN_ETHEREUM
      case TRANSACTION_TYPE_ETH_TO_POLYGON:
        return BLOCKCHAIN_POLYGON
      case TRANSACTION_TYPE_POLYGON_TO_ETH:
        return BLOCKCHAIN_ETHEREUM
      case TRANSACTION_TYPE_BSC_TO_POLYGON:
        return BLOCKCHAIN_POLYGON
      case TRANSACTION_TYPE_POLYGON_TO_BSC:
        return BLOCKCHAIN_BINANCE
    }
  }

  get signatures () {
    return this.validators
      .filter((item) => [EVENT_BURNED, EVENT_LOCKED].includes(item.event))
      .map((item) => item?.message?.flatSig)
      .filter((item) => !!item)
  }

  get isFullSigned () {
    return this.signatures.length === +process.env.VUE_APP_VALIDATORS_COUNT
  }

  get signParams () {
    console.log('signParams this', this)
    const messages = this.validators
      .filter((item) => [EVENT_BURNED, EVENT_LOCKED].includes(item.event))
      .map((item) => item?.message)
    return messages.length > 0 ? messages[0].rawParams : null
  }

  get isPending () {
    return [TRANSACTION_STATUS_LOCKED, TRANSACTION_STATUS_BURNED].includes(this.status)
  }

  get isFinish () {
    return [TRANSACTION_STATUS_MINTED, TRANSACTION_STATUS_UNLOCKED].includes(this.status)
  }

  static fromServer (data) {
    return new BackendTransaction({
      ...data,
      validators: (data?.relations?.Message || [])
    })
  }
}

export default BackendTransaction
