import {
  WEB3_PROVIDER_METAMASK,
  PROVIDER_BY_CHAIN_ID, WEB3_PROVIDER_WALLETCONNECT,
} from '@/constants/blockchain'
import { getProvider as getProviderMetamask } from '@/servicies/metamask'
import { getProviderAsync as getProviderWalletConnect } from '@/servicies/walletConnect'

const web3 = {}

const settingWeb3 = (web3) => {
  web3.eth.transactionConfirmationBlocks = 1
  web3.transactionPollingTimeout = 3000
  web3.eth.transactionPollingTimeout = 3000
  web3.eth.handleRevert = true
}

export async function getWeb3Async ({ blockchain, walletGroup, newInitWallet = false, walletBlockchain }) {
  if (walletGroup) {
    if (walletGroup === WEB3_PROVIDER_METAMASK) {
      const provider = getProviderMetamask()
      if (!web3.metamask) {
        const Web3 = (await import(/* webpackChunkName: "web3" */ 'web3')).default
        web3.metamask = new Web3(provider)
        settingWeb3(web3.metamask)
      }
      return web3.metamask
    }
    if (walletGroup === WEB3_PROVIDER_WALLETCONNECT) {
      const provider = await getProviderWalletConnect()
      if (walletBlockchain) {
        provider.setDefaultChain('eip155:' + walletBlockchain)
      }
      if (!web3.walletConnect) {
        const Web3 = (await import(/* webpackChunkName: "web3" */ 'web3')).default
        web3.walletConnect = new Web3(provider)
        settingWeb3(web3.walletConnect)
      }
      if (newInitWallet) {
        web3.walletConnect.setProvider(provider)
      }
      return web3.walletConnect
    }
  }

  const key = `by_chain_id_${blockchain}`
  if (!web3[key]) {
    const Web3 = (await import(/* webpackChunkName: "web3" */ 'web3')).default
    web3[key] = new Web3(new Web3.providers.HttpProvider(PROVIDER_BY_CHAIN_ID[blockchain]))
    settingWeb3(web3[key])
  }
  return web3[key]
}
